import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, withStyles, LinearProgress, Paper } from "@material-ui/core";
import { useState } from "react";
import { useRef } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: "absolute",
        width: 150,
        marginTop: "-106%",
        height: "36px"
    },
    volume: {
        display: "inline-block",
        width: 20,
        textAlign: "center"
    }
}));

const BorderLinearProgress = withStyles(() => ({
    root: {
        margin: 5,
        display: "inline-block",
        width: "calc(100% - 80px)"
    },
    colorPrimary: {
        backgroundColor: "#22246254"
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#1a90ff"
    }
}))(LinearProgress);

function UserAudioIndicator(props) {
    const { audioLevel, volume, isCheckAudio } = props;
    const classes = useStyles(props);
    const [isPlay, setIsPlay] = useState(false);
    const audio = useRef(null);

    useEffect(() => {
        if (audio.current) {
            audio.current.volume = volume;
        }
        return () => {
            removeAudioPauseEventListener();
        };
    }, [volume]);

    useEffect(() => {
        if (!isCheckAudio) {
            removeAudioPauseEventListener();
        }
    }, [isCheckAudio]);

    const removeAudioPauseEventListener = () => {
        if (audio.current) {
            try {
                audio.current.removeEventListener("pause", pauseListener);
            } catch {
                console.log("Event already removed or something");
            }
        }
    };

    const handlePlay = () => {
        if (!isPlay) {
            audio.current = new Audio("/media/audio/call_test.mp3");
            audio.current.play();

            audio.current.addEventListener("pause", pauseListener);
        } else {
            audio.current.pause();
        }
        setIsPlay(prev => !prev);
    };

    const pauseListener = () => {
        setIsPlay(false);
    };

    return (
        isCheckAudio && (
            <Paper className={classes.wrapper}>
                <span className={classes.volume}>{Math.round(volume * 100)}</span>
                <BorderLinearProgress
                    className={classes.linearProgress}
                    variant="determinate"
                    value={audioLevel ? (audioLevel > 2 / 3 ? 100 : audioLevel * 150) : 30}
                />
                <button
                    type="button"
                    size="small"
                    className="btn btn-outline-primary"
                    style={{ border: "none" }}
                    onClick={handlePlay}
                    data-dismiss="modal"
                >
                    {isPlay ? <PauseIcon /> : <PlayArrowIcon />}
                </button>
            </Paper>
        )
    );
}

const mapStateToProps = state => {
    return {
        isCheckAudio: state.event.isCheckAudio,
        volume: state.event.volume
    };
};

const mapDispatchToProps = dispatch => ({
    // hideNotification: () => dispatch(eventStore.actions.hideNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAudioIndicator);
