import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import { TextField } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Modal from "react-modal";
import { Formik } from "formik";
import { Link } from "react-router-dom";
Modal.setAppElement("#root");

import {
    Paper,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
    Select,
    FormControl,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import LobbyNotify from "../../../../../components/LobbyNotify";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import { Portlet, PortletBody } from "../../../../../partials/content/Portlet";
import Notify from "../../../../../components/Notify";
import EventLayout from "../../../../../components/EventLayout";
import MeetingVideo from "./MeetingVideoLink";
import { meeting as useStyles } from "../../../../../components/CommonStyles";

import * as eventStore from "../../../../../store/ducks/event.duck";
import * as eventsStore from "../../../../../store/ducks/customers/index.duck";
import { ORIGINAL_ROOMNAME } from "../../../../../components/JitsiMeeting";
const title = `Welcome to ${process.env.REACT_APP_TITLE}!`;
const sub_title = process.env.REACT_APP_SUB_TITLE;
import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import clsx from "clsx";

function Meeting(props) {
    const {
        isEvent,
        isStartMeeting,
        startMeeting,
        endMeeting,
        screenMode,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        event_link,
        getEventLink,
        authError,
        intl
    } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [lang, setLang] = React.useState(ORIGINAL_ROOMNAME);
    const [volume, setVolume] = React.useState(0.5);
    const [isOriginal, setIsOriginal] = React.useState(false);
    const [roomsList, setRoomsList] = React.useState([]);
    const [isNotifyOpen, setIsNotifyOpen] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState("");
    const [currentEvent, setCurrentEvent] = useState(null);
    const restart = localStorage.getItem("restart");
    const event_name = props.match.params.id;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [authFlag, setAuthFlag] = useState(false);
    const [passwordFlag, setpasswordFlag] = useState(false);
    const [loadingButtonStyle, setLoadingButtonStyle] = useState({
        paddingRight: "2.5rem"
    });
    useEffect(() => {
        const local_userName = localStorage.getItem("local_userName");
        const local_userPassword = localStorage.getItem("local_password");
        if (local_userName) {
            setIsModalOpen(false);
            setUserName(local_userName);
            setUserPassword(local_userPassword);
            setpasswordFlag(true);
            const queryParams = new URLSearchParams(window.location.search);
            const name = queryParams.get("name");
            getEventLink(event_name, {
                name,
                password: local_userPassword,
                userName: local_userName
            });
            setEventLinkFetched(true);
            setAuthFlag(false);
            localStorage.clear();
        } else {
            setIsModalOpen(true);
            const modalElements = document.getElementsByClassName("ReactModalPortal")[0];
            modalElements.style.position = "absolute";
            modalElements.style.bottom = "0";
            modalElements.style.height = "100%";
            modalElements.style.width = "100%";
        }
    }, []);
    const [eventLinkFetched, setEventLinkFetched] = useState(false);
    const [isLoading, setLoadingMeeting] = useState(false);
    const handleModalClose = () => {
        const password = document.getElementById("meetingUserPassword").value;
        const user_Name = document.getElementById("meetingUserName").value;
        if (password != "" && user_Name != "") {
            setLoadingMeeting(true);
            setUserName(user_Name);
            setUserPassword(password);
            setpasswordFlag(true);
            const queryParams = new URLSearchParams(window.location.search);
            const name = queryParams.get("name");
            getEventLink(event_name, { name, password, userName: user_Name });
            setEventLinkFetched(true);
            setAuthFlag(false);
        }
    };

    // useEffect hook to monitor changes in event_link
    useEffect(() => {
        if (eventLinkFetched && event_link) {
            setLoadingMeeting(false);
            let event_rooms = [];
            if (event_link) {
                event_rooms = event_link.event_rooms;
            }

            let resArr = [];
            event_rooms.forEach(item => {
                const i = resArr.findIndex(x => x.room.room_name === item.room.room_name);
                if (i <= -1) {
                    resArr.push(item);
                }
            });

            // Update rooms list
            setRoomsList(resArr);
            // Final meeting start
            handleStartMeeting(userName);
            setPageLoaded(true);
            setEventLinkFetched(false);
        } else {
            setTimeout(() => {
                setAuthFlag(true);
            }, 500);
            setLoadingMeeting(false);
        }
    }, [
        event_link,
        eventLinkFetched,
        restart,
        userName,
        setRoomsList,
        handleStartMeeting,
        setNotifyMessage,
        setIsNotifyOpen,
        setPageLoaded
    ]);

    // const handleValidateName = () => {
    // };
    const handleCloseNotification = () => {
        setIsNotifyOpen(false);
    };
    function handleStartMeeting(userName) {
        if (lang) {
            if (event_link) {
                startMeeting(event_link.id, userName);
            }
        }
    }

    function handleEndMeeting() {
        endMeeting();
    }

    function handleRestart() {
        localStorage.setItem("local_event_list", event_link);
        localStorage.setItem("local_userName", userName);
        localStorage.setItem("local_password", userPassword);
        window.location.reload();
    }

    function handleLangChange(event) {
        setLang(event.target.value);
    }

    function handleOriginal() {
        setIsOriginal(prevState => !prevState);
    }

    function handleDown(event) {
        setVolume(prevState => {
            if (prevState >= 0.1) {
                return prevState - 0.1;
            } else {
                return 0.0;
            }
        });
    }

    function handleUp(event) {
        setVolume(prevState => {
            if (prevState <= 0.9) {
                return prevState + 0.1;
            } else {
                return 1.0;
            }
        });
    }

    /**
     * Handle CameraSetting Change
     * @param {String} newCameraInput
     */
    function handleVideoSettingChange(e) {
        jitsiMeeting.setCameraSetting(e.target.value);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     */
    function handleAudioOutputSettingChange(e) {
        jitsiMeeting.setAudioOutputSetting(e.target.value);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     */
    async function handleAudioInputSettingChange(e) {
        jitsiMeeting.setAudioInputSetting(e.target.value);
    }

    return (
        <div>
            {pageLoaded ? (
                <EventLayout
                    event={event_link}
                    screenMode={screenMode}
                    isStartMeeting={isStartMeeting}
                    hidden={isStartMeeting}
                >
                    <div
                        className={`row ${!isStartMeeting ? classes.eventPlace : ""}`}
                        id="eventPlace"
                        style={{ margin: "auto", paddingTop: "60px" }}
                    >
                        <div
                            className="col-md-8"
                            style={{ marginTop: "auto", marginLeft: "auto" }}
                            id="videoPlace"
                        >
                            <Notify />
                            <LobbyNotify />
                            {event_link && isStartMeeting ? (
                                <MeetingVideo
                                    event={event_link}
                                    roomname={lang}
                                    volume={volume}
                                    size="sm"
                                    isOriginal={isOriginal}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className={`col-md-4 ${classes.control} ${screenMode < 0 &&
                                classes.fullWidthControl}`}
                        >
                            <div className="row">
                                <div className="col-md-12 text-right">
                                    <Button
                                        target="_blank"
                                        href="http://olyusei.speedtestcustom.com/"
                                        color="primary"
                                    >
                                        Speed Test
                                    </Button>
                                </div>
                                <div className="col-md-12">
                                    <Paper className={classes.langControl}>
                                        <FormControl
                                            variant="outlined"
                                            disabled={false}
                                            className={classes.formControl}
                                            error={lang ? false : true}
                                        >
                                            <Select
                                                value={lang}
                                                onChange={handleLangChange}
                                                displayEmpty
                                                name="language"
                                            >
                                                <MenuItem value="">
                                                    <em>
                                                        {intl.formatMessage({
                                                            id: "EVENT.LANG.SELECT_LANG"
                                                        })}
                                                    </em>
                                                </MenuItem>
                                                <MenuItem value={ORIGINAL_ROOMNAME}>
                                                    {intl.formatMessage({
                                                        id: "EVENT.LANG.ORIGINAL"
                                                    })}
                                                </MenuItem>
                                                {event_link &&
                                                    roomsList.length > 0 &&
                                                    roomsList.map((row, index) => {
                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                value={row.room.room_name}
                                                            >
                                                                <img
                                                                    src={toAbsoluteUrl(
                                                                        `/media/flags-svg/${row.room.output_lang.lang_flag}`
                                                                    )}
                                                                    alt={
                                                                        row.room.output_lang
                                                                            .lang_name
                                                                    }
                                                                    className={classes.flag}
                                                                />
                                                                {row.room.output_lang.lang_label}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Paper>
                                </div>
                                <div className="col-md-12">
                                    <Paper className={classes.volumeControl}>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            className={classes.volume}
                                            onClick={handleDown}
                                        >
                                            <VolumeDownIcon />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color="primary"
                                            className={classes.volume}
                                            onClick={handleUp}
                                        >
                                            <VolumeUpIcon />
                                        </Button>
                                        <Button
                                            variant="contained"
                                            size="medium"
                                            color={`${isOriginal ? "primary" : "default"}`}
                                            className={classes.volume}
                                            onClick={handleOriginal}
                                        >
                                            + Ori
                                        </Button>
                                    </Paper>
                                </div>
                                <div className="col-md-12">
                                    <Portlet>
                                        <PortletBody>
                                            <div className={classes.tableWrapper}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow key="title"></TableRow>
                                                        <TableRow key="detail">
                                                            <TableCell component="th" scope="row">
                                                                {intl.formatMessage({
                                                                    id: "EVENTS.TABLE_HEADER.DETAIL"
                                                                })}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event_link
                                                                    ? event_link.detail
                                                                    : ""}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow key="start_datetime">
                                                            <TableCell component="th" scope="row">
                                                                {intl.formatMessage({
                                                                    id:
                                                                        "EVENTS.TABLE_HEADER.START_DATE"
                                                                })}
                                                            </TableCell>
                                                            <TableCell>
                                                                {event_link
                                                                    ? event_link.start_datetime
                                                                    : ""}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </div>
                                            <Grid container className={classes.root} spacing={3}>
                                                <Grid item xs={12}>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        spacing={1}
                                                    ></Grid>
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        spacing={2}
                                                    >
                                                        <Grid item xs={6}>
                                                            {isStartMeeting ? (
                                                                <Button
                                                                    className={classes.paper}
                                                                    disabled={!isStartMeeting}
                                                                    onClick={handleEndMeeting}
                                                                >
                                                                    {intl.formatMessage({
                                                                        id:
                                                                            "EVENT.ACTION.END_MEETING"
                                                                    })}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className={classes.paper}
                                                                    disabled={isStartMeeting}
                                                                    onClick={handleRestart}
                                                                >
                                                                    {intl.formatMessage({
                                                                        id:
                                                                            "EVENT.ACTION.START_MEETING"
                                                                    })}
                                                                </Button>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Button
                                                                className={classes.paper}
                                                                disabled={!isStartMeeting}
                                                                onClick={handleRestart}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: "EVENT.ACTION.RESET"
                                                                })}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </PortletBody>
                                    </Portlet>
                                </div>

                                <div className="col-md-12">
                                    <Paper className={classes.langControl}>
                                        <FormControl
                                            variant="outlined"
                                            disabled={false}
                                            className={classes.formControl}
                                        >
                                            <InputLabel htmlFor="side_cameraSetting">
                                                {intl.formatMessage({
                                                    id: "VIDEO.SETTING.CAMERA"
                                                })}
                                            </InputLabel>
                                            <Select
                                                native
                                                onChange={handleVideoSettingChange}
                                                displayEmpty
                                                inputProps={{
                                                    name: "side_cameraSetting",
                                                    id: "side_cameraSetting"
                                                }}
                                                value={
                                                    cameraSetting === "" &&
                                                    cameraDevices &&
                                                    cameraDevices.length > 0
                                                        ? cameraDevices[0].deviceId
                                                        : cameraSetting
                                                }
                                            >
                                                {cameraDevices.map(device => {
                                                    return (
                                                        <option
                                                            key={device.deviceId}
                                                            value={device.deviceId}
                                                        >
                                                            {device.label}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Paper>
                                </div>
                                <div className="col-md-12">
                                    <Paper className={classes.langControl}>
                                        <FormControl
                                            variant="outlined"
                                            disabled={false}
                                            className={classes.formControl}
                                        >
                                            <InputLabel htmlFor="side_audioOutputSetting">
                                                {intl.formatMessage({
                                                    id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                                                })}
                                            </InputLabel>
                                            <Select
                                                native
                                                onChange={handleAudioOutputSettingChange}
                                                displayEmpty
                                                inputProps={{
                                                    name: "side_audioOutputSetting",
                                                    id: "side_audioOutputSetting"
                                                }}
                                                value={
                                                    audioOutputSetting === "" &&
                                                    audioOutputDevices &&
                                                    audioOutputDevices.length > 0
                                                        ? audioOutputDevices[0].deviceId
                                                        : audioOutputSetting
                                                }
                                            >
                                                {audioOutputDevices.map(device => {
                                                    return (
                                                        <option
                                                            key={device.deviceId}
                                                            value={device.deviceId}
                                                        >
                                                            {device.label}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Paper>
                                </div>
                                <div className="col-md-12">
                                    <Paper className={classes.langControl}>
                                        <FormControl
                                            variant="outlined"
                                            disabled={false}
                                            className={classes.formControl}
                                        >
                                            <InputLabel htmlFor="side_audioInputSetting">
                                                {intl.formatMessage({
                                                    id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                                                })}
                                            </InputLabel>
                                            <Select
                                                native
                                                onChange={handleAudioInputSettingChange}
                                                inputProps={{
                                                    name: "side_audioInputSetting",
                                                    id: "side_audioInputSetting"
                                                }}
                                                value={
                                                    audioInputSetting === "" &&
                                                    audioInputDevices &&
                                                    audioInputDevices.length > 0
                                                        ? audioInputDevices[0].deviceId
                                                        : audioInputSetting
                                                }
                                            >
                                                {audioInputDevices.map(device => {
                                                    return (
                                                        <option
                                                            key={device.deviceId}
                                                            value={device.deviceId}
                                                        >
                                                            {device.label}
                                                        </option>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Paper>
                                </div>
                            </div>
                        </div>
                    </div>
                </EventLayout>
            ) : (
                <div id={"MeetingLogin"}>
                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={handleModalClose}
                        contentLabel="Automatic Dialog"
                        className="modal-content"
                        id="ModalContent"
                        overlayClassName="modal-overlay"
                    >
                        <Snackbar
                            open={isNotifyOpen}
                            autoHideDuration={3000} // Duration before auto-close
                            onClose={handleCloseNotification}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center"
                            }}
                        >
                            <MuiAlert
                                elevation={6}
                                variant="filled"
                                severity="warning"
                                onClose={handleCloseNotification}
                            >
                                {notifyMessage}
                            </MuiAlert>
                        </Snackbar>
                        <div className="kt-grid kt-grid--ver kt-grid--root">
                            <div
                                id="kt_login"
                                className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
                            >
                                <div
                                    className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
                                    style={{ height: "100vh" }}
                                >
                                    <div
                                        className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                                        style={{
                                            backgroundImage: `url(${toAbsoluteUrl(
                                                "/media/bg/bg-4.jpg"
                                            )})`,
                                            height: "100%",
                                            backgroundSize: "cover"
                                        }}
                                    >
                                        <div className="kt-grid__item">
                                            <img
                                                alt="Logo"
                                                src={toAbsoluteUrl("/media/logos/logo-lg.png")}
                                                // style={{ width: "100%", maxWidth: "300px" }}
                                            />
                                        </div>
                                        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver">
                                            <div className="kt-grid__item kt-grid__item--middle">
                                                <h3 className="kt-login__title">{title}</h3>
                                                <h4 className="kt-login__subtitle">{sub_title}</h4>
                                            </div>
                                        </div>
                                        <div className="kt-grid__item">
                                            <div className="kt-login__info">
                                                <div className="kt-login__copyright">
                                                    &copy; 2020 {process.env.REACT_APP_TITLE}
                                                </div>
                                                <div className="kt-login__menu">
                                                    <Link to="/terms" className="kt-link">
                                                        Privacy
                                                    </Link>
                                                    <Link to="/terms" className="kt-link">
                                                        Legal
                                                    </Link>
                                                    <Link to="/terms" className="kt-link">
                                                        Contact
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
                                        style={{
                                            margin: "auto",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "38%",
                                                margin: "auto",
                                                textAlign: "center"
                                            }}
                                            className="kt-login__form"
                                        >
                                            <div
                                                className="kt-login__title"
                                                style={{ color: "#67666e", marginBottom: "50px" }}
                                            >
                                                <h3>
                                                    {intl.formatMessage({
                                                        id: "EVENT.LINKMEETING.LOGIN"
                                                    })}
                                                </h3>
                                            </div>
                                            <Formik
                                                initialValues={{
                                                    email: "",
                                                    password: ""
                                                }}
                                                validate={values => {
                                                    const errors = {};

                                                    if (!values.email) {
                                                        errors.email = intl.formatMessage({
                                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                        });
                                                    }
                                                    if (!values.password) {
                                                        errors.password = intl.formatMessage({
                                                            id: "AUTH.VALIDATION.REQUIRED_FIELD"
                                                        });
                                                    }

                                                    return errors;
                                                }}
                                            >
                                                {({
                                                    values,
                                                    status,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    isSubmitting
                                                }) => (
                                                    <form
                                                        noValidate={true}
                                                        autoComplete="off"
                                                        className="kt-form"
                                                        // onSubmit={submitFunction}
                                                    >
                                                        {authFlag && passwordFlag ? (
                                                            <div
                                                                role="alert"
                                                                className="alert alert-danger"
                                                            >
                                                                <div className="alert-text">
                                                                    {"Password is Incorrect"}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <TextField
                                                            id="meetingUserName"
                                                            type="email"
                                                            label="Your Name"
                                                            margin="normal"
                                                            className="kt-width-full"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            helperText={
                                                                touched.email && errors.email
                                                            }
                                                            error={Boolean(
                                                                touched.email && errors.email
                                                            )}
                                                        />
                                                        <TextField
                                                            id="meetingUserPassword"
                                                            type="password"
                                                            margin="normal"
                                                            label="Password"
                                                            className="kt-width-full"
                                                            name="password"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.password}
                                                            helperText={
                                                                touched.password && errors.password
                                                            }
                                                            error={Boolean(
                                                                touched.password && errors.password
                                                            )}
                                                        />
                                                        <div style={{ textAlign: "right" }}>
                                                            <button
                                                                type="button"
                                                                onClick={handleModalClose}
                                                                disabled={isLoading}
                                                                style={{
                                                                    height: "50px",
                                                                    marginTop: "10px",
                                                                    width: "30%",
                                                                    loadingButtonStyle
                                                                }}
                                                                className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                                                                    {
                                                                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": isLoading
                                                                    }
                                                                )}`}
                                                            >
                                                                {intl.formatMessage({
                                                                    id: "EVENT.LINKMEETING.JOIN"
                                                                })}
                                                            </button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        isEvent: state.customerReducer.isEvent,
        isStartMeeting: state.event.isStartMeeting,
        screenMode: state.event.screenMode,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        event_link: state.customerReducer.event_link,
        isEvent_link: state.customerReducer.isEvent_link,
        meetingUserName: state.customerReducer.userName,
        authError: state.customerReducer.authError
    };
};

const mapDispatchToProps = dispatch => ({
    startMeeting: (event_id, userName) =>
        dispatch(eventStore.actions.startLinkMeeting(event_id, userName)),
    getEventLink: (event_name, user) =>
        dispatch(eventsStore.actions.get_customer_meetinglink_event(event_name, user)),
    endMeeting: () => dispatch(eventStore.actions.endMeeting())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Meeting));
