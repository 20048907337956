/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React from "react";
import { Redirect, Route, Switch, withRouter, HashRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import AdminHomePage from "../pages/home/AdminHomePage";
import EmitterHomePage from "../pages/home/EmitterHomePage";
import InterpreterHomePage from "../pages/home/InterpreterHomePage";
import CustomerHomePage from "../pages/home/CustomerHomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import { UserRole } from "../components/utils/UserRole";
import EventWrapper from "../pages/home/event/EventWrapper";
// import MeetingLogin from "../pages/home/superadmins/users/LinkMeetingLogin";
// import MeetingLogin from "../pages/home/interpreters/events/Event";
// import MeetingLogin from "../pages/home/customers/events/EventLink";
import MeetingLogin from "../pages/home/customers/events/Meeting/MeetingLink";
import { isElectron } from "../components/utils/ElectronUtil";

const RootRoute = ({ isElec, children }) => {
    if (isElec) {
        return <HashRouter>{children}</HashRouter>;
    } else {
        return <>{children}</>;
    }
};

export const Routes = withRouter(({ history }) => {
    const lastLocation = useLastLocation();
    routerHelpers.saveLastLocation(lastLocation);

    const { isAuthorized, menuConfig, userLastLocation, userRole } = useSelector(
        ({ auth, urls, builder: { menuConfig } }) => {
            if (!auth.authToken) {
                routerHelpers.forgotLastLocation();
            }
            return {
                menuConfig,
                isAuthorized: auth.user != null,
                userLastLocation: routerHelpers.getLastLocation(),
                userRole: auth.user != null ? auth.user.role : -1
            };
        },
        shallowEqual
    );

    return (
        /* Create `LayoutContext` from current `history` and `menuConfig`. */
        <RootRoute isElec={isElectron()}>
            <LayoutContextProvider history={history} menuConfig={menuConfig}>
                <Switch>
                    {!isAuthorized ? (
                        /* Render auth page when user at `/auth` and not authorized. */
                        //   <AuthPage />
                        <Route path="/auth" component={AuthPage} />
                    ) : (
                        /* Otherwise redirect to root page (`/`) */
                        <Redirect from="/auth" to={userLastLocation} />
                    )}

                    {/* <Route path="/event/:id" component={Event} /> */}
                    <Route path="/event/:id" component={EventWrapper} />
                    {/* <Meeting event={currentEvent} /> */}
                    <Route path="/events/meeting/:id" component={MeetingLogin} />
                    <Route path="/error" component={ErrorsPage} />
                    <Route path="/logout" component={LogoutPage} />

                    {!isAuthorized ? (
                        /* Redirect to `/auth` when user is not authorized */
                        <Redirect to="/auth/login" />
                    ) : userRole === UserRole.SUPERADMIN || userRole === UserRole.ADMIN ? (
                        <Layout>
                            <HomePage userLastLocation={userLastLocation} />
                        </Layout>
                    ) : userRole === UserRole.EVENT_MANAGER ? (
                        <Layout>
                            <AdminHomePage userLastLocation={userLastLocation} />
                        </Layout>
                    ) : userRole === UserRole.EMITTER ? (
                        <Layout>
                            <EmitterHomePage userLastLocation={userLastLocation} />
                        </Layout>
                    ) : userRole === UserRole.INTERPRETER ? (
                        <Layout>
                            <InterpreterHomePage userLastLocation={userLastLocation} />
                        </Layout>
                    ) : (
                        <Layout>
                            <CustomerHomePage userLastLocation={userLastLocation} />
                        </Layout>
                    )}
                </Switch>
            </LayoutContextProvider>
        </RootRoute>
    );
});
