// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, push, onValue } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAr7aXoG-TUY_A9RCF-RK1_GdyA6rMkhkM",
    authDomain: "olyusei-2a742.firebaseapp.com",
    databaseURL: "https://olyusei-2a742-default-rtdb.firebaseio.com",
    projectId: "olyusei-2a742",
    storageBucket: "olyusei-2a742.firebasestorage.app",
    messagingSenderId: "133124797067",
    appId: "1:133124797067:web:42ab4995074e0a482e5a8e",
    measurementId: "G-TJ4CLHQ532"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { database, ref, push, onValue };
