import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_URL = BASE_URL + "auth/login";
export const REGISTER_URL = BASE_URL + "auth/register";
export const REQUEST_PASSWORD_URL = BASE_URL + "auth/forgot-password";

export const ME_URL = BASE_URL + "auth/me";

export function login(email, password) {
    return axios.post(LOGIN_URL, { email, password });
}

export function register(email, name, password, password_confirmation) {
    return axios.post(REGISTER_URL, { email, name, password, password_confirmation });
}

export function requestPassword(email) {
    return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}

export function update_profile(user) {
    return axios.post(BASE_URL + "auth/update_user", user, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

export function resetPassword() {
    return axios.post(BASE_URL + "auth/reset_password");
}

export function resetPersonalPassword(newpassword, password, email) {
    return axios.post(BASE_URL + "auth/reset_password", {
        password: password,
        newpassword: newpassword,
        email: email
    });
}

export function resetPasswordGuest(data) {
    return axios.post(BASE_URL + "auth/resetPassword", data);
}
