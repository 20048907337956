import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Button, Grid, CircularProgress } from "@material-ui/core";
import Chat from "../../../../../components/Chat1";
import ToolBar from "../../../../../components/Toolbar";
import SelectDeviceDialog from "../../../../../components/SelectDeviceDialog";
import SelectBgDialog from "../../../../../components/SelectBgDialog";
import { toAbsoluteUrl } from "../../../../../../_metronic";
import { meetingVideo as useStyles } from "../../../../../components/CommonStyles";
import * as eventStore from "../../../../../store/ducks/event.duck";
import * as messageStore from "../../../../../store/ducks/messages.duck";
import { UserRole } from "../../../../../components/utils/UserRole";
import MeetContainer from "../../../../../components/MeetContainer";
import { calcSidePlaceHeight } from "../../../../../components/utils/VideoLayoutUtils";
import useHandleMosaicView from "../../../../../components/useHandleMosaicView";
import useHandleMute from "../../../../../components/useHandleMute";
import VideoTrack from "../../../../../components/VideoTrack";
import RemoteVideo from "../../../../../components/RemoteVideo";
import { EventType } from "../../../../../components/utils/EventType";
import { jitsiMeeting } from "../../../../../components/JitsiMeeting";
import AudioPlace from "../../../../../components/AudioPlace";
import { API_STATUS } from "../../../../../crud/helper";
import { database, ref, onValue } from "../../../../../../firebase";
import { set } from "firebase/database";
import { partition } from "lodash";

function MeetingVideo(props) {
    const {
        event,
        isEndMeeting,
        roomname,
        isMuted,
        isChatBoxOpen,
        openAudioOutputSettingDlg,
        setOpenAudioOutputSettingDlg,
        openVideoSettingDlg,
        setOpenVideoSettingDlg,
        openAudioInputSettingDlg,
        setOpenAudioInputSettingDlg,
        openBgSettingDlg,
        setOpenBgSettingDlg,
        isRaise,
        openChatBox,
        isMosaic,
        // isNotify,
        user,
        isLoadingToken,
        authToken,
        isJoining,
        connectStatus,
        addStat,
        showLobbyNotification,
        startStatId,
        cameraSetting,
        audioOutputSetting,
        audioInputSetting,
        disableMic,
        disableVideo,
        focusedId,
        cameraDevices,
        audioOutputDevices,
        audioInputDevices,
        participants,
        setParticipants,
        messageList,
        getMessages,
        setToParticipant,
        audioLevels,
        localTracks,
        isOriginal,
        audioTracks,
        isSubTitle,
        intl
    } = props;
    const classes = useStyles(props);
    const [messages, setMessages] = useState([]);
    const userString = localStorage.getItem("persist:auth");
    let userID = "";

    if (userString) {
        const authData = JSON.parse(userString); // Parse the stored string
        if (authData.user) {
            const bufferUserID = JSON.parse(authData.user);
            const noSpaces = bufferUserID.name;
            userID = noSpaces.split(" ").join("");
        }
    }
    useEffect(() => {
        const messageRef = ref(database, `messages/${userID}/message`);
        onValue(messageRef, snapshot => {
            const data = snapshot.val();
            if (data) {
                const formattedMessages = Object.values(data);
                setMessages(snapshot.val());
                showLobbyNotification({
                    success: "success",
                    senderId: "senderId",
                    lobbymessage: snapshot.val()
                });
                set(ref(database, `messages/${userID}`), null)
                    .then(() => {
                        console.log("Message is deleted!");
                    })
                    .catch(error => {
                        console.log(error);
                    });
            } else {
                setMessages([]);
            }
        });
    }, []);
    useEffect(() => {
        if (isEndMeeting) {
            unload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEndMeeting]);

    useEffect(() => {
        console.log("🚀 ~ useEffect ~ authToken:", authToken);
        console.log("🚀 ~ useEffect ~ isLoadingToken:", isLoadingToken);
        if (isLoadingToken == API_STATUS.SUCCESS && authToken) {
            startJitsiMeeting();
        }
    }, [isLoadingToken, authToken]);

    useEffect(() => {
        getMessages();

        calcSidePlaceHeight();
        return () => {
            if (jitsiMeeting.connection) {
                unload();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (jitsiMeeting.room) {
            postStat(3, roomname);
            jitsiMeeting.changeLangTrack(roomname);
            jitsiMeeting.setRoomFeature();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomname]);

    useHandleMute(isMuted);

    useHandleMosaicView(isMosaic, props.screenMode, participants, isSubTitle);

    useEffect(() => {
        jitsiMeeting.handleRaiseHand(isRaise);
    }, [isRaise]);

    useEffect(() => {
        jitsiMeeting.setIsWithFloor(isOriginal);
    }, [isOriginal]);

    async function startJitsiMeeting() {
        if (!event) {
            return;
        }

        const appId = process.env.REACT_APP_APP_ID;
        jitsiMeeting.connect(appId, authToken, event, roomname, {
            isMicOn: true,
            isMuted: true
        });

        postStat(0, roomname);
    }
    function renderRemoteParticipant(p) {
        const pId = p.getId();
        const displayName = p.getDisplayName();
        const participant = jitsiMeeting.room.getParticipantById(p.getId());
        const remoteVideoTracks = participant.getTracks();
        const cameraRemoteTrack = remoteVideoTracks.find(
            t => t.videoType === "camera" && !t.disposed
        );
        const screenRemoteTrack = remoteVideoTracks.find(
            t => t.videoType === "desktop" && !t.disposed
        );
        const selfShowCameraTrack = localTracks.find(t => t.videoType === "camera" && !t.disposed);
        const selfShowScreenTrack = localTracks.find(t => t.videoType === "desktop" && !t.disposed);
        const videoTrack = jitsiMeeting.remoteTracks[pId].find(
            t => t.getType() === "video" && !t.disposed
        );
        let videoElement = "";
        let placeClass = classes.participantContainer;

        // Focus Element
        if (
            !(event.event_type === EventType.CONFERENCE || event.event_type === EventType.WEB) &&
            pId === focusedId
        ) {
            placeClass += ` ${classes.participantContainerFocused}`;
        }

        let _isMuted = true;
        _isMuted = !!jitsiMeeting.remoteTracks[pId].find(t => t.getType() === "audio" && t.muted);

        if (jitsiMeeting.remoteTracks[pId]) {
            if (parseInt(p.getProperty("role")) === UserRole.INTERPRETER) {
                placeClass += " interpreterPlace";
            }

            if (parseInt(p.getProperty("role")) !== UserRole.OBSERVER) {
                if (remoteVideoTracks) {
                    videoElement = (
                        <div
                            style={
                                remoteVideoTracks.length == 2
                                    ? { marginTop: "-75%", height: "72px", paddingTop: "46%" }
                                    : {}
                            }
                        >
                            {cameraRemoteTrack ? (
                                <VideoTrack
                                    id={`${pId}cameraVideo`}
                                    className={classes.video}
                                    muted={true}
                                    videoTrack={{
                                        jitsiTrack: cameraRemoteTrack
                                    }}
                                    style={
                                        screenRemoteTrack
                                            ? {
                                                  width: "40%",
                                                  marginLeft: "5%"
                                              }
                                            : {}
                                    }
                                />
                            ) : (
                                ""
                            )}
                            {screenRemoteTrack ? (
                                <VideoTrack
                                    id={`${pId}screenVideo`}
                                    className={classes.video}
                                    muted={true}
                                    videoTrack={{
                                        jitsiTrack: screenRemoteTrack
                                    }}
                                    style={{
                                        width: cameraRemoteTrack ? "40%" : undefined,
                                        marginLeft: isMosaic
                                            ? cameraRemoteTrack
                                                ? "45%"
                                                : "0%"
                                            : cameraRemoteTrack
                                            ? "5%"
                                            : undefined
                                    }}
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    );

                    jitsiMeeting.setReceiverConstraints(pId);
                } else {
                    <VideoTrack
                        id="bigVideo"
                        className={`${classes.bigVideo}`}
                        muted={true}
                        videoTrack={{ jitsiTrack: _getBigVideoTrack() }}
                    />;
                }
            }
        }

        // Participant's output language
        const p_output = p.getProperty("output");
        const is_non = p_output ? p_output.includes("non-") : false;

        let output_lang = null;
        if (is_non) {
            output_lang = { lang_name: "ORI" };
        } else {
            const p_output_room = event.event_rooms.find(
                event_room => event_room.room.room_name === p_output
            );

            output_lang = p_output_room ? p_output_room.room.output_lang : null;
        }

        const isHand = !!p.getProperty("hand");

        return (
            <div
                key={pId}
                id={`${pId}Place`}
                className={placeClass}
                aria-hidden="true"
                onClick={() => handleClickSidePlace(pId)}
            >
                <RemoteVideo
                    id={pId}
                    displayName={displayName}
                    baseClasses={classes}
                    openChatBox={openChatBox}
                    setToParticipant={setToParticipant}
                    messageList={messageList}
                    lang={intl.locale}
                    isNotify={false}
                    isMicOn={!is_non}
                    isMuted={_isMuted}
                    audioLevels={audioLevels}
                    isMosaic={isMosaic}
                    output_lang={output_lang}
                    isHand={isHand}
                >
                    {videoElement}
                </RemoteVideo>
            </div>
        );
    }

    function unload() {
        postStat(2, roomname);

        jitsiMeeting.unload();
    }

    function postStat(status, _roomname) {
        const currentRoom = event.event_rooms.filter(
            event_room => event_room.room.room_name === _roomname
        );
        let stat = {
            event_id: event.id,
            status: status,
            room_id: currentRoom.length > 0 ? currentRoom[0].room_id : 0,
            start_stat_id: startStatId ? startStatId : 0
        };
        addStat(stat);
    }

    function handleClickSidePlace(pId) {
        if (!jitsiMeeting.room) return;

        if (pId === "selfPlace") {
            jitsiMeeting.setFocusedId(jitsiMeeting.room.myUserId());
        } else {
            jitsiMeeting.setReceiverConstraints(pId);
            jitsiMeeting.setFocusedId(pId);
        }
        setParticipants(jitsiMeeting.room.getParticipants());
    }

    function _getBigVideoTrack() {
        let newBigVideoTrack = localTracks.find(t => t.getType() === "video");
        const showSelfScreenTrack = localTracks.find(t => t.videoType === "desktop");
        const showSelfCameraTrack = localTracks.find(t => t.videoType === "camera");
        if (!jitsiMeeting.room || !focusedId) {
            const screenTrack = localTracks.find(t => t.videoType === "desktop");
            const cameraTrack = localTracks.find(t => t.videoType === "camera");
            const showSelfScreenTrack = localTracks.find(t => t.videoType === "desktop");
            const showSelfCameraTrack = localTracks.find(t => t.videoType === "camera");
            return { screenTrack, cameraTrack, showSelfScreenTrack, showSelfCameraTrack };
        }

        if (focusedId === jitsiMeeting.room.myUserId()) {
            const screenTrack = localTracks.find(t => t.videoType === "desktop");
            const cameraTrack = localTracks.find(t => t.videoType === "camera");
            const showSelfScreenTrack = localTracks.find(t => t.videoType === "desktop");
            const showSelfCameraTrack = localTracks.find(t => t.videoType === "camera");
            return { screenTrack, cameraTrack, showSelfScreenTrack, showSelfCameraTrack };
        } else {
            const participant = jitsiMeeting.room.getParticipantById(focusedId);
            if (!participant) return null;

            if (participant.getProperty("role") !== UserRole.OBSERVER) {
                console.log(
                    "🚀 ~ _getBigVideoTrack ~ participant.getProperty(role):",
                    participant.getProperty("role")
                );
            }

            if (participant.getProperty("role") === UserRole.INTERPRETER) {
                console.log(
                    "🚀 ~ _getBigVideoTrack ~ participant.getProperty(role):",
                    participant.getProperty("role")
                );
            }
            const tracks = participant.getTracks();
            if (tracks.find(t => t.getType() === "video")) {
                const screenTrack = tracks.find(t => t.videoType === "desktop");
                const cameraTrack = tracks.find(t => t.videoType === "camera");
                const showSelfScreenTrack = localTracks.find(t => t.videoType === "desktop");
                const showSelfCameraTrack = localTracks.find(t => t.videoType === "camera");
                return { screenTrack, cameraTrack, showSelfScreenTrack, showSelfCameraTrack };
            } else {
                newBigVideoTrack = null;
            }
        }

        return { newBigVideoTrack, showSelfScreenTrack, showSelfCameraTrack };
    }

    // TODO:
    function handleHoverEvent(event) {
        // console.log("🚀 ~ MeetingVideo ~ event:", event);
    }

    // TODO:
    function handleLeaveEvent(event) {
        // console.log("🚀 ~ MeetingVideo ~ event:", event);
    }

    /**
     * Handle CameraSetting Change
     * @param {String} newCameraInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleVideoSettingChange(newCameraInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setCameraSetting(newCameraInput);
        }
        setOpenVideoSettingDlg(isOpen);
    }

    /**
     * Handle AudioOutputSetting Change
     * @param {String} newAudioOutput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleAudioOutputSettingChange(newAudioOutput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioOutputSetting(newAudioOutput);
        }
        setOpenAudioOutputSettingDlg(isOpen);
    }

    /**
     * Handle AudioInputSetting Change
     * @param {String} newAudioInput
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    async function handleAudioInputSettingChange(newAudioInput, isOpen = true) {
        if (isOpen) {
            jitsiMeeting.setAudioInputSetting(newAudioInput);
        }
        setOpenAudioInputSettingDlg(isOpen);
    }

    function sendTextMessage(id, message) {
        if (id === "all") {
            jitsiMeeting.room.sendTextMessage(message);
        } else {
            jitsiMeeting.room.sendPrivateTextMessage(id, message);
        }
    }

    /**
     * Handle CameraSetting Change
     * @param {Object} background
     * @param {Boolean} isOpen if true, Setting Dialog is open, if false, dialog is close by cancel or ok
     */
    function handleBgSettingChange(background, isOpen = true) {
        // if (isOpen) {
        jitsiMeeting.setBackgroundSetting(background);
        // }
        setOpenBgSettingDlg(isOpen);
    }

    function validUsers(p) {
        const role = parseInt(p.getProperty("role"));
        return role > 0 && role <= UserRole.USER && role !== UserRole.INTERPRETER;
    }

    return (
        <>
            <MeetContainer className="row">
                <Grid item xs={12} style={{ height: "100%" }}>
                    <div
                        id="localPlace"
                        className={classes.localPlace}
                        onMouseEnter={handleHoverEvent}
                        onMouseLeave={handleLeaveEvent}
                    >
                        {isJoining == 2 ? (
                            <>
                                <div className={classes.avatar}>
                                    <img
                                        src={
                                            event.pic
                                                ? process.env.REACT_APP_FILE_URL + event.pic
                                                : toAbsoluteUrl("/media/logos/logo-trans.png")
                                        }
                                        alt="avatar"
                                    />
                                </div>

                                <div className={classes.chatContainer}>
                                    <Chat
                                        open={isChatBoxOpen}
                                        setIsChatDlg={openChatBox}
                                        sendTextMessage={sendTextMessage}
                                        participants={participants}
                                        lobbyMembers={[]}
                                    />
                                </div>

                                <ToolBar
                                    disableMic={false}
                                    disableVideo={false}
                                    disableShare={false}
                                />
                                {_getBigVideoTrack() ? (
                                    _getBigVideoTrack().screenTrack ? (
                                        <VideoTrack
                                            id="bigVideo"
                                            className={`${classes.bigVideo}`}
                                            muted={true}
                                            videoTrack={{
                                                jitsiTrack: _getBigVideoTrack().screenTrack
                                            }}
                                            style={
                                                _getBigVideoTrack().cameraTrack
                                                    ? { marginLeft: "5%", width: "70%" }
                                                    : {}
                                            }
                                        />
                                    ) : _getBigVideoTrack().cameraTrack ? (
                                        <VideoTrack
                                            id="bigVideo"
                                            className={`${classes.bigVideo}`}
                                            muted={true}
                                            videoTrack={{
                                                jitsiTrack: _getBigVideoTrack().cameraTrack
                                            }}
                                            style={
                                                _getBigVideoTrack().screenTrack
                                                    ? { marginLeft: "5%", width: "70%" }
                                                    : {}
                                            }
                                        />
                                    ) : null
                                ) : (
                                    ""
                                )}
                                {_getBigVideoTrack() ? (
                                    _getBigVideoTrack().cameraTrack ? (
                                        <VideoTrack
                                            id="bigVideo"
                                            className={`${classes.bigVideo}`}
                                            muted={true}
                                            videoTrack={{
                                                jitsiTrack: _getBigVideoTrack().cameraTrack
                                            }}
                                            style={
                                                _getBigVideoTrack().screenTrack
                                                    ? {
                                                          marginLeft: "75%",
                                                          width: "10%",
                                                          marginTop: "12%"
                                                      }
                                                    : {}
                                            }
                                        />
                                    ) : (
                                        <VideoTrack
                                            id="bigVideo"
                                            className={`${classes.bigVideo}`}
                                            muted={true}
                                            videoTrack={{
                                                jitsiTrack: _getBigVideoTrack().cameraTrack
                                            }}
                                            // style={
                                            //     _getBigVideoTrack().screenTrack
                                            //         ? {
                                            //               marginLeft: "75%",
                                            //               width: "10%",
                                            //               marginTop: "12%"
                                            //           }
                                            //         : {}
                                            // }
                                        />
                                    )
                                ) : (
                                    ""
                                )}
                                <div
                                    className={
                                        isMosaic ? classes.mosaicOverlay : classes.hideSelfVideo
                                    }
                                />
                                <div
                                    id="sidePlace"
                                    className={
                                        isMosaic ? classes.mosaicRemoteContainer : classes.sidePlace
                                    }
                                >
                                    <div className="sideWrapper">
                                        <div id="remotePlace" className={classes.remotePlace}>
                                            <div
                                                id="selfPlace"
                                                className={`${classes.participantContainer} ${
                                                    jitsiMeeting.room &&
                                                    focusedId === jitsiMeeting.room.myUserId()
                                                        ? classes.participantContainerFocused
                                                        : ""
                                                }`}
                                                aria-hidden="true"
                                                onClick={() => handleClickSidePlace("selfPlace")}
                                            >
                                                <div className={classes.videoContainer}>
                                                    {_getBigVideoTrack() ? (
                                                        _getBigVideoTrack().showSelfScreenTrack ? (
                                                            <VideoTrack
                                                                id="selfVideo"
                                                                className={classes.video}
                                                                muted={true}
                                                                videoTrack={{
                                                                    jitsiTrack: _getBigVideoTrack()
                                                                        .showSelfScreenTrack
                                                                }}
                                                                style={
                                                                    _getBigVideoTrack()
                                                                        .showSelfCameraTrack
                                                                        ? {
                                                                              marginLeft: "5%",
                                                                              width: "40%"
                                                                          }
                                                                        : {}
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                    {_getBigVideoTrack() ? (
                                                        _getBigVideoTrack().showSelfCameraTrack ? (
                                                            <VideoTrack
                                                                id="selfVideo"
                                                                className={classes.video}
                                                                muted={true}
                                                                videoTrack={{
                                                                    jitsiTrack: _getBigVideoTrack()
                                                                        .showSelfCameraTrack
                                                                }}
                                                                style={{
                                                                    marginLeft: isMosaic
                                                                        ? _getBigVideoTrack()
                                                                              .showSelfScreenTrack
                                                                            ? "45%"
                                                                            : "0%"
                                                                        : "5%",
                                                                    ...(_getBigVideoTrack()
                                                                        .showSelfScreenTrack
                                                                        ? { width: "40%" }
                                                                        : {})
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="displayName">
                                                    {user.name}(
                                                    {intl.formatMessage({
                                                        id: "CHAT.ME"
                                                    })}
                                                    )
                                                </div>
                                                {isRaise && (
                                                    <div className={classes.hand}>
                                                        <i className="fas fa-hand-paper"></i>
                                                    </div>
                                                )}
                                            </div>
                                            {participants
                                                .filter(p => validUsers(p))
                                                .map(p => renderRemoteParticipant(p))}
                                        </div>
                                    </div>
                                </div>

                                {Object.keys(audioTracks).map(key => (
                                    <AudioPlace
                                        id={`${key}audio`}
                                        key={key}
                                        audioTrack={{
                                            jitsiTrack: audioTracks[key].audioTrack
                                        }}
                                        participantId={key}
                                        _muted={!!audioTracks[key].muted}
                                        _volume={
                                            audioTracks[key].volume ? audioTracks[key].volume : 0
                                        }
                                    />
                                ))}

                                {isSubTitle && (
                                    <div id="subTitleWrapper" className={classes.subTitleWrapper}>
                                        <div id="subTitle" className={classes.subTitle}>
                                            {event.subtitle && event.subtitle !== "" && (
                                                <iframe
                                                    title={event.title}
                                                    src={event.subtitle}
                                                    className={classes.subTitleIframe}
                                                ></iframe>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {/* Camera Setting Dialog */}
                                <SelectDeviceDialog
                                    id="cameraSetting"
                                    title={intl.formatMessage({
                                        id: "VIDEO.SETTING.CAMERA.TITLE"
                                    })}
                                    label={intl.formatMessage({
                                        id: "VIDEO.SETTING.CAMERA"
                                    })}
                                    isOpen={openVideoSettingDlg}
                                    devices={cameraDevices}
                                    currentValue={cameraSetting}
                                    onChange={handleVideoSettingChange}
                                />

                                {/* Audio Output Setting Dialog */}
                                <SelectDeviceDialog
                                    id="audioOutputSetting"
                                    title={intl.formatMessage({
                                        id: "VIDEO.SETTING.AUDIO_OUPUTS.TITLE"
                                    })}
                                    label={intl.formatMessage({
                                        id: "VIDEO.SETTING.AUDIO_OUPUTS"
                                    })}
                                    isOpen={openAudioOutputSettingDlg}
                                    devices={audioOutputDevices}
                                    currentValue={audioOutputSetting}
                                    onChange={handleAudioOutputSettingChange}
                                />

                                {/* Audio Input Setting Dialog */}
                                <SelectDeviceDialog
                                    id="audioInputSetting"
                                    title={intl.formatMessage({
                                        id: "VIDEO.SETTING.AUDIO_INPUTS_TITLE"
                                    })}
                                    label={intl.formatMessage({
                                        id: "VIDEO.SETTING.AUDIO_INPUTS"
                                    })}
                                    isOpen={openAudioInputSettingDlg}
                                    devices={audioInputDevices}
                                    currentValue={audioInputSetting}
                                    onChange={handleAudioInputSettingChange}
                                />

                                {/* Virtual Background Setting Dialog */}
                                <SelectBgDialog
                                    id="bgSetting"
                                    title={intl.formatMessage({
                                        id: "VIDEO.SETTING.VIRTUAL_BACKGROUND.TITLE"
                                    })}
                                    isOpen={openBgSettingDlg}
                                    eventBg={event.bg_ic}
                                    cameraSetting={cameraSetting}
                                    currentValue={event.bg_pic}
                                    onChange={handleBgSettingChange}
                                />
                            </>
                        ) : (
                            <div className={classes.loadingSection}>
                                <CircularProgress size={24} className={""} />
                                <span style={{ margin: 10 }}>
                                    {isLoadingToken == API_STATUS.ERROR
                                        ? "Can't connect to the meeting. Please try again."
                                        : connectStatus == API_STATUS.LOADING
                                        ? "The meeting has not started yet. Please try later..."
                                        : connectStatus == API_STATUS.SUCCESS
                                        ? "The meeting has started, you will be admitted soon..."
                                        : "Connecting..."}
                                </span>
                            </div>
                        )}
                    </div>
                </Grid>
            </MeetContainer>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authToken: state.auth.authToken,
        isEndMeeting: state.event.isEndMeeting,
        startStatId: state.event.startStatId,
        room: state.event.room,
        authToken: state.event.authToken,
        isLoadingToken: state.event.isLoadingToken,
        connectStatus: state.event.connectStatus,
        isJoining: state.event.isJoining,
        isMuted: state.event.isMuted,
        isVideoOn: state.event.isVideoOn,
        cameraSetting: state.event.cameraSetting,
        audioOutputSetting: state.event.audioOutputSetting,
        audioInputSetting: state.event.audioInputSetting,
        cameraDevices: state.event.cameraDevices,
        audioOutputDevices: state.event.audioOutputDevices,
        audioInputDevices: state.event.audioInputDevices,
        isChatBoxOpen: state.event.isChatBoxOpen,
        openVideoSettingDlg: state.event.openVideoSettingDlg,
        openAudioOutputSettingDlg: state.event.openAudioOutputSettingDlg,
        openAudioInputSettingDlg: state.event.openAudioInputSettingDlg,
        openBgSettingDlg: state.event.openBgSettingDlg,
        isRaise: state.event.isRaise,
        isMosaic: state.event.isMosaic,
        screenMode: state.event.screenMode,
        isCamera: state.event.isCamera,
        isShareOtherCamera: state.event.isShareOtherCamera,
        disableMic: state.event.disableMic,
        disableVideo: state.event.disableVideo,
        participants: state.event.participants,
        toParticipant: state.event.toParticipant,
        messageList: state.messages.messageList,
        isSidePlaceShow: state.event.isSidePlaceShow,
        audioLevels: state.event.audioLevels,
        localTracks: state.event.localTracks,
        focusedId: state.event.focusedId,
        audioTracks: state.event.audioTracks,
        isSubTitle: state.event.isSubTitle
    };
};

const mapDispatchToProps = dispatch => ({
    showLobbyNotification: data =>
        dispatch(
            eventStore.actions.showLobbyNotification(data.success, data.senderId, data.lobbymessage)
        ),
    addStat: data => dispatch(eventStore.actions.addStat(data)),
    openChatBox: isChatBoxOpen => dispatch(eventStore.actions.openChatBox(isChatBoxOpen)),
    setOpenAudioInputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioInputSettingDlg(isOpen)),
    setOpenAudioOutputSettingDlg: isOpen =>
        dispatch(eventStore.actions.setOpenAudioOutputSettingDlg(isOpen)),
    setOpenVideoSettingDlg: isOpen => dispatch(eventStore.actions.setOpenVideoSettingDlg(isOpen)),
    setOpenBgSettingDlg: isOpen => dispatch(eventStore.actions.setOpenBgSettingDlg(isOpen)),
    setParticipants: participants => dispatch(eventStore.actions.setParticipants(participants)),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant)),
    getMessages: () => dispatch(messageStore.actions.getMessages())
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MeetingVideo));
