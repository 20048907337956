import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { makeStyles, ListItem, ListItemText, ListItemSecondaryAction } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import BlockIcon from "@material-ui/icons/Block";
import { Dropdown } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { colors } from "@material-ui/core";
import moment from "moment";
import { FileIcon, defaultStyles } from "react-file-icon";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import * as eventStore from "../store/ducks/event.duck";
import { UserRole } from "./utils/UserRole";
import { formatFileSize, getFileName } from "./utils/fileUtil";
import { validMembers } from "./utils/RoomUtils";
import { database, ref, push } from "../../firebase";
import { set } from "firebase/database";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles(theme => ({
    chatBox: {
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        height: props => (props.isChatBoxBottomOffset ? "calc(100% - 56px)" : "100%"),
        transition: "left 5s",
        position: "absolute",
        width: "100%",
        minWidth: 250
    },
    chat: {
        height: "100%"
    },
    chatPortlet: {
        backgroundColor: "transparent",
        height: "100%"
    },
    chatPortalFoot: {
        padding: "0.8rem 0.5rem 0.8rem 0.5rem !important"
    },
    chatBody: {
        position: "relative",
        height: "100%",
        overflow: "auto"
    },
    chatPanel: {
        // position: 'relative',
        // maxHeight: '400px'
    },
    chatInput: {
        display: "flex"
    },
    chatToolbar: {
        marginTop: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    chatEditor: {
        width: "100%",
        display: "flex"
    },
    textInput: {
        resize: "none",
        width: "100%",
        border: "0",
        outline: "none",
        backgroundColor: "transparent",
        color: "#a2a5b9"
    },
    hideVisibility: {
        visibility: "hidden",
        left: "-300px"
    },
    commonChatContentLayout: {
        margin: "0.2rem 0 !important",
        borderRadius: "16px !important",
        clear: "both",
        padding: "0.3rem 0.6rem !important"
    },
    otherChatContentLayout: {
        backgroundColor: "rgba(10, 187, 135, 0.5) !important",
        borderBottomLeftRadius: "0 !important",
        float: "left !important"
    },
    privateChatContentLayout: {
        backgroundColor: "rgb(167, 105, 74) !important",
        borderBottomLeftRadius: "0 !important",
        float: "left !important"
    },
    myChatContentLayout: {
        backgroundColor: "rgba(93, 120, 255, 0.5) !important",
        borderBottomRightRadius: "0 !important"
    },
    myChatPrivateContentLayout: {
        backgroundColor: "rgb(167, 105, 74) !important",
        borderBottomRightRadius: "0 !important"
    },
    commonChatContent: {
        color: "#f1eded !important",
        marginTop: "0 !important",
        fontWeight: "100 !important",
        fontSize: "1rem",
        overflowWrap: "anywhere"
    },
    myChatContent: {},
    otherChatContent: {},
    white: {
        color: colors.common.white
    },
    chatBoxToolbar: {
        display: "flex"
    },
    chatHeader: {
        padding: "0 0.4rem",
        color: "#fbfb18 !important",
        fontWeight: 400,
        fontSize: "0.85rem"
    },
    // File
    filePreviewContent: {
        position: "relative",
        maxWidth: 100,
        padding: 5,
        background: "#2c2b2b",
        borderRadius: 5
    },
    fileIcon: {
        maxWidth: 50
    },
    previewMedia: {
        maxWidth: 50
    },
    fileMeta: {
        fontSize: "0.8rem",
        color: "white"
    },
    btnCancel: {
        position: "absolute",
        right: 5,
        top: 5,
        borderRadius: "50%",
        color: "white",
        boxShadow: "0px 0px 13px 0px rgba(0, 0, 0, 0.1)",
        justifyContent: "center",
        diplay: "flex",
        alignItems: "center",
        width: 16,
        height: 16,
        cursor: "pointer"
    },
    progressOverlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.7)"
    },
    fileUrl: {
        color: "white",
        "&:hover": {
            color: "#d7d7d7"
        }
    },
    msgFilePreview: {
        maxWidth: 70,
        marginLeft: "auto",
        marginRight: "auto"
    }
}));

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </span>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
        return (
            <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                <ul className="list-unstyled">{React.Children.toArray(children)}</ul>
            </div>
        );
    }
);

const ALL = <FormattedMessage id="CHAT.ALL" />;
const AllLobbyUser = <FormattedMessage id="AllLobbyUser" />;
const AllMemberUser = <FormattedMessage id="AllMember" />;
function MessageElement(props) {
    const { message } = props;
    const classes = useStyles(props);

    const isValidURL = str => {
        try {
            new URL(str);
            return true;
        } catch (_) {
            return false;
        }
    };

    const renderMessage = str => {
        if (isValidURL(str)) {
            return (
                <a
                    href={str}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className={classes.fileUrl}
                >
                    <div className={classes.msgFilePreview}>
                        <FileIcon
                            extension={str.split(".").pop()}
                            {...defaultStyles[str.split(".").pop()]}
                        />
                    </div>

                    {getFileName(str)}
                </a>
            );
        } else {
            return str;
        }
    };

    return (
        <>
            {message.displayName === "me" ? (
                <div
                    className={`${classes.commonChatContentLayout} ${
                        message.type === "private"
                            ? classes.myChatPrivateContentLayout
                            : classes.myChatContentLayout
                    } kt-chat__message kt-chat__message--right kt-chat__message--brand`}
                >
                    <div className="kt-chat__user">
                        <span className={classes.chatHeader}>{message.time}</span>
                    </div>
                    <div
                        className={`${classes.commonChatContent} ${classes.myChatContent} kt-chat__text`}
                    >
                        {renderMessage(message.content)}
                    </div>
                </div>
            ) : (
                <div
                    className={`${classes.commonChatContentLayout} ${
                        message.type === "private"
                            ? classes.privateChatContentLayout
                            : classes.otherChatContentLayout
                    } kt-chat__message kt-chat__message--success`}
                >
                    <div className="kt-chat__user">
                        <span className={classes.chatHeader}>
                            {message.displayName} {message.time ? `, ${message.time}` : ""}
                        </span>
                    </div>
                    <div
                        className={`${classes.commonChatContent} ${classes.otherChatContent} kt-chat__text`}
                    >
                        {renderMessage(message.content)}
                    </div>
                </div>
            )}
        </>
    );
}

function Chat(props) {
    const {
        open,
        setIsChatDlg,
        sendTextMessage,
        lobbyMembers,
        isMessageUpdated,
        updateMessage,
        updateMessageSuccess,
        user,
        participants,
        toParticipant,
        setToParticipant,
        chatMessageList,
        intl
    } = props;
    const classes = useStyles(props);
    const [message, setMessage] = React.useState("");
    const chatBodyRef = React.useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = React.useRef(null);
    const [filePreview, setFilePreview] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const _validMembers = lobbyMembers.length != 0 ? validMembers(lobbyMembers) : [];
    let userID = "";
    const userString = localStorage.getItem("persist:auth");
    if (userString) {
        const authData = JSON.parse(userString); // Parse the stored string
        if (authData.user) {
            const bufferUserID = JSON.parse(authData.user);
            const noSpaces = bufferUserID.name;
            userID = noSpaces.split(" ").join("");
        }
    }
    const sendMessageToLobby = async (message, senderId, receiverId) => {
        if (!message.trim()) return; // Prevent empty messages
        try {
            const messageRef = ref(database, `messages/${receiverId}`);
            await set(messageRef, {
                message
            });
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };
    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end"
            });
        }
    }, [participants]);

    useEffect(() => {
        if (open) {
            focusChatBox();
        }
    }, [open]);

    useEffect(() => {
        if (isMessageUpdated && chatBodyRef.current) {
            chatBodyRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end"
            });
            updateMessageSuccess();
        }
    }, [isMessageUpdated, participants, user, updateMessageSuccess]);
    async function sendLobbyMessage(toParticipant, value, participantLobby) {
        await axios.post(BASE_URL + "sendMessage", {
            user_id: toParticipant, // Send to specific user
            content: value,
            userNick: participantLobby
        });
    }
    function onEnterPress(e) {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            const newMessage = e.target.value;
            sendMessage(newMessage);
        }
    }

    async function sendMessage(value) {
        if (selectedFile) {
            // Upload file if selected
            const formData = new FormData();
            formData.append("file", selectedFile);
            formData.append("event_id", props.event_id);
            formData.append("file_type", selectedFile.type);
            formData.append("file_size", selectedFile.size);
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}files`,
                    formData,
                    {
                        onUploadProgress: progressEvent => {
                            const progress = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(progress);
                        }
                    }
                );
                setSelectedFile(null);
                setFilePreview(null);
                setUploadProgress(0);

                console.log("File uploaded successfully:", response.data);

                const file_message = `${process.env.REACT_APP_FILE_URL + response.data.file_name}`;

                updateMessage({
                    displayName: "me",
                    content: file_message,
                    time: _getCurrentDateTime(),
                    type: toParticipant === "all" ? "public" : "private"
                });
                chatBodyRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end"
                });
                sendTextMessage(toParticipant, file_message);
            } catch (error) {
                console.log("🚀 ~ sendMessage ~ error uploading file:", error);
            }
        }
        if (value.trim() === "") {
            return;
        }

        updateMessage({
            displayName: "me",
            content: value,
            time: _getCurrentDateTime(),
            type: toParticipant === "all" ? "public" : "private"
        });
        chatBodyRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end"
        });
        console.log(toParticipant);
        if (toParticipant == "all") {
            sendTextMessage(toParticipant, value);
            lobbyMembers.forEach(participant => {
                sendMessageToLobby(value, userID, participant.nick.split(" ").join(""));
            });
        } else if (toParticipant == "allMember") {
            sendTextMessage("all", value);
        } else if (toParticipant == "allLobby") {
            lobbyMembers.forEach(participant => {
                sendMessageToLobby(value, userID, participant.nick.split(" ").join(""));
            });
        } else {
            const p = participants.find(participant => participant.getId() === toParticipant);
            if (p) {
                sendTextMessage(toParticipant, value);
            } else {
                const participantLobby = lobbyMembers.find(
                    participant => participant.id === toParticipant
                );
                sendMessageToLobby(value, userID, participantLobby.nick.split(" ").join(""));
                // sendLobbyMessage(toParticipant, value, participantLobby.nick);
            }
        }
        setMessage("");
    }

    function _renderMessage(m, index) {
        if (m.id) {
            const p = participants.find(participant => m.id === participant.getId());
            const _message = {
                displayName: p ? p.getDisplayName() : "",
                content: m.text,
                type: m.isPrivate ? "private" : "public",
                time: _getCurrentDateTime()
            };
            return <MessageElement key={index} message={_message} />;
        } else {
            return <MessageElement key={index} message={m} />;
        }
    }

    function onMessageChange(e) {
        setMessage(e.target.value);
    }

    function handleChangeDlg(e) {
        setIsChatDlg(false);
    }

    function handleClickUser(value) {
        setToParticipant(value);
    }

    function getDisplayName(participantId) {
        if (participantId === "all") {
            return "ALL";
        }
        if (participantId === "allLobby") {
            return "AllLobbyUser";
        }
        if (participantId === "allMember") {
            return "AllMember";
        }
        const participant = participants.find(participant => participant.getId() === participantId);
        const participantLobby = lobbyMembers.find(participant => participant.id === participantId);
        if (participant) {
            return participant.getDisplayName();
        } else if (participantLobby) {
            return participantLobby.nick;
        }
        return "";
    }

    function focusChatBox() {
        document.getElementById("chatInput").focus();
    }

    function _getCurrentDateTime() {
        return moment().format("h:mm a, MMM D");
    }

    function handleFileChange(e) {
        const file = e.target.files[0];
        setSelectedFile(file);

        // If the file is an image or video, set preview; otherwise set null for non-media files
        if (file && (file.type.startsWith("image/") || file.type.startsWith("video/"))) {
            setFilePreview(URL.createObjectURL(file));
        } else {
            setFilePreview(null);
        }
    }

    function onHandleFileSelect() {
        fileInputRef.current.click();
    }

    function cancelFile() {
        setSelectedFile(null);
        setFilePreview(null);
    }

    return (
        <div id="chatBox" className={`${open ? "" : classes.hideVisibility} ${classes.chatBox} `}>
            <div className={`kt-chat ${classes.chat}`}>
                <div className={`kt-portlet kt-portlet--last ${classes.chatPortlet}`}>
                    <div className="kt-portlet__head">
                        <div className="kt-chat__head ">
                            <div className="kt-chat__left">
                                <div className={`kt-chat__label ${classes.white}`}>
                                    {getDisplayName(toParticipant)}
                                </div>
                            </div>
                            <div className={classes.chatBoxToolbar}>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        id="dropdown-basic"
                                        drop={"up"}
                                        navbar={false}
                                    >
                                        <IconButton
                                            color="primary"
                                            aria-label="More"
                                            className={classes.volume}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu as={CustomMenu}>
                                        <Dropdown.Item
                                            eventKey={toParticipant}
                                            key="all"
                                            onClick={() => handleClickUser("all")}
                                        >
                                            {ALL}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={toParticipant}
                                            key="allMember"
                                            onClick={() => handleClickUser("allMember")}
                                        >
                                            {AllMemberUser}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            eventKey={toParticipant}
                                            key="allLobby"
                                            onClick={() => handleClickUser("allLobby")}
                                        >
                                            {AllLobbyUser}
                                        </Dropdown.Item>
                                        {participants.map((participant, index) => {
                                            return participant.getDisplayName() ? (
                                                <Dropdown.Item
                                                    eventKey={index}
                                                    key={index}
                                                    onClick={() =>
                                                        handleClickUser(participant.getId())
                                                    }
                                                >
                                                    {participant.getDisplayName()}
                                                </Dropdown.Item>
                                            ) : (
                                                ""
                                            );
                                        })}
                                        {(_validMembers || []).length > 0 &&
                                            _validMembers.map((p, index) => (
                                                <Dropdown.Item
                                                    eventKey={index}
                                                    key={index}
                                                    onClick={() => handleClickUser(p.id)}
                                                >
                                                    {p.nick}
                                                    {"(in Lobby)"}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                                <IconButton
                                    color="primary"
                                    aria-label="More"
                                    className={classes.volume}
                                    onClick={handleChangeDlg}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                    <div className={`kt-portlet__body ${classes.chatBody}`} id="chatBody">
                        <div
                            className={`kt-chat__messages kt-chat__messages--solid ${classes.chatPanel}`}
                            ref={chatBodyRef}
                        >
                            {chatMessageList.map((m, index) => _renderMessage(m, index))}
                        </div>
                    </div>
                    <div
                        className={`kt-portlet__foot ${classes.chatPortalFoot}`}
                        onClick={focusChatBox}
                    >
                        {selectedFile && (
                            <div className={classes.filePreviewContent}>
                                <span className={classes.btnCancel} onClick={cancelFile}>
                                    <i className="fa fa-times"></i>
                                </span>

                                <>
                                    {filePreview ? (
                                        <>
                                            {/* Image or Video Preview */}
                                            {selectedFile.type.startsWith("image/") && (
                                                <img
                                                    src={filePreview}
                                                    alt="Preview"
                                                    className={classes.previewMedia}
                                                />
                                            )}
                                            {selectedFile.type.startsWith("video/") && (
                                                <video controls className={classes.previewMedia}>
                                                    <source
                                                        src={filePreview}
                                                        type={selectedFile.type}
                                                    />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}
                                        </>
                                    ) : (
                                        // Use FileIcon for non-image/video files
                                        <div className={classes.fileIcon}>
                                            <FileIcon
                                                extension={selectedFile.name.split(".").pop()}
                                                {...defaultStyles[
                                                    selectedFile.name.split(".").pop()
                                                ]}
                                            />
                                        </div>
                                    )}
                                    {/* Circular Progress Bar Overlay */}
                                    {uploadProgress > 0 && (
                                        <div className={classes.progressOverlay}>
                                            <CircularProgressbar
                                                value={uploadProgress}
                                                text={`${uploadProgress}%`}
                                            />
                                        </div>
                                    )}
                                    <div className={classes.fileMeta}>
                                        <div>
                                            {selectedFile.name}
                                            <br />
                                            {formatFileSize(selectedFile.size)} {selectedFile.type}
                                        </div>
                                    </div>
                                </>
                            </div>
                        )}
                        <div className={`kt-chat__input ${classes.chatInput}`}>
                            <div className={classes.chatEditor}>
                                {/* <textarea placeholder="Type here..." style="height: 62px; margin-top: 0px; margin-bottom: 0px;"></textarea> */}
                                <textarea
                                    id="chatInput"
                                    placeholder={intl.formatMessage({
                                        id: "CHAT.TYPE_HERE"
                                    })}
                                    value={message}
                                    className={classes.textInput}
                                    onKeyDown={onEnterPress}
                                    onChange={onMessageChange}
                                ></textarea>
                                <input
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                />
                                {(!message || message.length === 0) && !selectedFile ? (
                                    <IconButton
                                        color="primary"
                                        aria-label="Send"
                                        onClick={onHandleFileSelect}
                                        className={classes.activeBtn}
                                    >
                                        <AttachmentIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        color="primary"
                                        aria-label="Send"
                                        onClick={() => sendMessage(message)}
                                        className={classes.activeBtn}
                                    >
                                        <SendIcon />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const props = {
        isMessageUpdated: state.event.isMessageUpdated,
        user: state.auth.user,
        toParticipant: state.event.toParticipant,
        chatMessageList: state.event.chatMessageList,
        event_id: state.event.event_id
    };

    return {
        ...props,
        isChatBoxBottomOffset: state.auth.user
            ? state.auth.user.role
            : UserRole.USER !== UserRole.INTERPRETER
    };
};

const mapDispatchToProps = dispatch => ({
    updateMessage: message => dispatch(eventStore.actions.updateMessage(message)),
    updateMessageSuccess: () => dispatch(eventStore.actions.updateMessageSuccess()),
    setToParticipant: toParticipant => dispatch(eventStore.actions.setToParticipant(toParticipant))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Chat));
