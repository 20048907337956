import _, { cond } from "lodash";
import { initOptions, options, confOptions, isInterpreter } from "./utils/RoomUtils";
import store from "../store/store";
import * as eventStore from "../store/ducks/event.duck";
import { UserRole } from "./utils/UserRole";
import { mutePresenterVideo } from "./utils/LocalVideoTrackUtils";
import { AudioMixer } from "./AudioMixer";
import { EventType } from "./utils/EventType";
import { createVirtualBackgroundEffect } from "./virtual-background/index";
import { VIRTUAL_BACKGROUND_TYPE, VIRTUAL_BACKGROUNDS } from "./virtual-background/constants";
import { toAbsoluteUrl } from "../../_metronic";
import { toDataURL } from "./virtual-background/functions";
import LocalRecordingManager from "./LocalRecordingManager";
import { CHANNEL_TYPE } from "./utils/constants";

export const JitsiMeetJS = window.JitsiMeetJS;

export const ORIGINAL_ROOMNAME = "original";

class JitsiMeeting {
    constructor() {
        this.init();
    }

    init() {
        this.connection = null;
        this.room = null;
        this.localTracks = [];
        this.remoteTracks = {};
        this.inputRoomname = null;
        this.videoParticipants = [];
        this.receiveMessageObject = {};
        this.cameraDevices = [];
        this.audioOutputDevices = [];
        this.audioInputDevices = [];
        this.focusedId = null;
        this.audioTracks = {};
        this.cameraSetting = "";
        this.background = "none";
        this.isVideoOn = false;
        this.isCamera = true;
        this.isShareOtherCamera = false;
        this.isMicOn = false;
        this.isWithFloor = false;
        this.isMuted = false;
        this.isSubTitle = false;
        // Interpreter
        this.outputRoomname = "";
        this.selfRoomname = null;
        this.emitterRoomname = null;
        this.audioMixer = new AudioMixer();
        this.volume = 0.5;
        this.bass = 4;
        this.treble = 4;
        this.isHandOverReady = false;
        // Webinar Users
        this.disableMic = false;
        this.disableVideo = false;
        this.videoEffect = null;
        this.recordingManagers = [];
        this.getDevices();
    }

    async connect(appId, authToken, event, inputRoomname, otherOptions = {}) {
        this.init();

        this.event = event;
        this.user = store.getState().auth.user;
        console.log("🚀 ~ JitsiMeeting ~ connect ~ this.user:", this.user);
        console.log("🚀 ~ JitsiMeeting ~ connect ~ this.event:", this.event);
        this.inputRoomname = inputRoomname;

        if (
            this.event.event_type === EventType.WEBINAR && this.user
                ? this.user.role
                : UserRole.USER >= UserRole.USER
        ) {
            this.setDisableMic(true);
            this.setDisableVideo(true);
        }

        if (otherOptions.isMuted) {
            this.changeMute(otherOptions.isMuted);
        }

        if (otherOptions.isMicOn) {
            this.changeMicOn(otherOptions.isMicOn);
        }

        // Interpreter
        if (!_.isEmpty(otherOptions.selfRoomname)) {
            this.setSelfRoomname(otherOptions.selfRoomname);
        }
        if (!_.isEmpty(otherOptions.outputRoomname)) {
            this.setOutputRoomname(otherOptions.outputRoomname);
        }

        JitsiMeetJS.init(initOptions);
        JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);

        let _authToken = null;
        if ((this.user ? this.user.role : UserRole.USER) <= UserRole.INTERPRETER) {
            _authToken = authToken;
        }

        this.connection = new JitsiMeetJS.JitsiConnection(appId, _authToken, options);

        this.onConnectionSuccess = this.onConnectionSuccess.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess
        );
        this.onConnectionFailed = this.onConnectionFailed.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );
        this.disconnect = this.disconnect.bind(this);
        this.connection.addEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect
        );
        this.onDeviceListChanged = this.onDeviceListChanged.bind(this);
        JitsiMeetJS.mediaDevices.addEventListener(
            JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
            this.onDeviceListChanged
        );

        this.connection.connect();
        console.log(
            "🚀 ~ file: JitsiMeeting.js:102 ~ JitsiMeeting ~ connect ~ connection:",
            this.connection
        );
        if (
            this.cameraDevices.length === 0 &&
            this.audioOutputDevices.length === 0 &&
            this.audioInputDevices.length === 0
        ) {
            this.getDevices();
        }
    }

    getDevices() {
        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};

        // const tracks = await JitsiMeetJS.createLocalTracks({
        //     devices: ["audio", "video"]
        // });
        // tracks.forEach(async t => {
        //     await t.dispose();
        // });

        if (
            JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output") ||
            JitsiMeetJS.mediaDevices.isDeviceListAvailable()
        ) {
            JitsiMeetJS.mediaDevices.enumerateDevices(devices => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:125 ~ JitsiMeeting ~ getDevices ~ devices:",
                    devices
                );
                devices.forEach(device => {
                    switch (device.kind) {
                        case "audiooutput":
                            _audioOutputDevices.push(device);
                            break;
                        case "audioinput":
                            _audioInputDevices.push(device);
                            break;
                        case "videoinput":
                            _cameraDevices.push(device);
                            break;
                        default:
                            break;
                    }
                });

                // TODO: Check if states are updated below
                if (_audioOutputDevices.length > 0) {
                    this.setAudioOutputDevices(_audioOutputDevices);
                    this.setAudioOutputSetting(_audioOutputDevices[0].deviceId);
                }
                if (_audioInputDevices.length > 0) {
                    mediaOptions.push("audio");
                    restOptions.micDeviceId = _audioInputDevices[0].deviceId;
                    this.setAudioInputDevices(_audioInputDevices);
                    this.setAudioInputSetting(_audioInputDevices[0].deviceId);
                }
                if (_cameraDevices.length > 0) {
                    if (this.isVideoOn) {
                        restOptions.cameraDeviceId = _cameraDevices[0].deviceId;
                        if (_cameraDevices.length > 0) {
                            mediaOptions.push("video");
                        }
                    }
                    this.setCameraDevices(_cameraDevices);
                    this.setCameraSetting(_cameraDevices[0].deviceId);
                }

                this.onLocalTracks = this.onLocalTracks.bind(this);
                if (this.connection) {
                    JitsiMeetJS.createLocalTracks({
                        devices: mediaOptions,
                        ...restOptions
                    })
                        .then(this.onLocalTracks)
                        .catch(error => {
                            console.log(
                                "🚀 ~ file: JitsiMeeting.js:171 ~ JitsiMeeting ~ getDevices ~ error:",
                                error
                            );
                        });
                }
            });
        } else {
            console.log(
                "🚀 ~ file: JitsiMeeting.js:176 ~ JitsiMeeting ~ getDevices ~",
                "Please select"
            );
        }
    }

    setAudioOutputSetting(newAudioOutput) {
        this.audioOutputSetting = newAudioOutput;
        JitsiMeetJS.mediaDevices.setAudioOutputDevice(newAudioOutput);
        store.dispatch(eventStore.actions.setAudioOutputSetting(newAudioOutput));
    }

    async setAudioInputSetting(newAudioInputSetting = null) {
        if (newAudioInputSetting) {
            this.audioInputSetting = newAudioInputSetting;

            store.dispatch(eventStore.actions.setAudioInputSetting(this.audioInputSetting));
        }

        const currentLocalAudioTrack = this.localTracks.find(track => track.getType() === "audio");
        console.log(
            "🚀 ~ JitsiMeeting ~ setAudioInputSetting ~ currentLocalAudioTrack:",
            currentLocalAudioTrack
        );

        if (currentLocalAudioTrack && !currentLocalAudioTrack.disposed) {
            await currentLocalAudioTrack.dispose().catch(e => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:204 ~ JitsiMeeting ~ awaitcurrentLocalAudioTrack.dispose ~ e:",
                    e
                );
            });
        }

        if (this.isMicOn) {
            JitsiMeetJS.createLocalTracks({
                devices: ["audio"],
                micDeviceId: this.audioInputSetting
            })
                .then(async ([audioTrack]) => {
                    if (this.isMuted) {
                        await audioTrack.mute();
                    } else {
                        await audioTrack.unmute();
                    }

                    if (this.room && this.room.room) {
                        await this.room.addTrack(audioTrack).catch(err => {
                            console.log(
                                "🚀 ~ file: JitsiMeeting.js:220 ~ JitsiMeeting ~ awaitthis.room.addTrack ~ err:",
                                err
                            );
                        });
                    }

                    this.updateLocalTracks([audioTrack]);
                })
                .catch(err => {
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:227 ~ JitsiMeeting ~ setAudioInputSetting ~ err:",
                        err
                    );
                });
        } else {
            this.removeLocalTrackbyType("audio");
        }
    }

    startRecording(options) {
        console.log("🚀 ~ JitsiMeeting ~ startRecording ~ options:", options);
        const channels = [];
        if (options.floor) {
            channels.push({ roomname: ORIGINAL_ROOMNAME, label: ORIGINAL_ROOMNAME });
        }

        if (options.languages) {
            options.roomsList.forEach(room => {
                channels.push({
                    roomname: room.room.room_name,
                    label: room.room.output_lang ? room.room.output_lang.lang_label : ""
                });
            });
        }
        console.log("🚀 ~ JitsiMeeting ~ startRecording ~ channels:", channels);
        for (let i = 0; i < channels.length; i++) {
            const recordingManager = new LocalRecordingManager();

            recordingManager
                .startLocalRecording(store, {
                    title: this.event.title,
                    ...{ ...options, inputRoomname: channels[i] }
                })
                .then(() => {
                    store.dispatch(eventStore.actions.updateRecordingStatus(true));
                })
                .catch(error => {
                    if (error.message == "NoLocalStreams") {
                        console.log(
                            "🚀 ~ JitsiMeeting ~ LocalRecordingManager.startLocalRecording ~ error.message:",
                            error.message
                        );
                    } else {
                        console.log(
                            "🚀 ~ JitsiMeeting ~ LocalRecordingManager.startLocalRecording ~ error:",
                            error
                        );
                    }
                });

            this.recordingManagers.push(recordingManager);
        }
    }

    stopRecording() {
        console.log("🚀 ~ JitsiMeeting ~ stopRecording ~");
        for (let i = 0; i < this.recordingManagers.length; i++) {
            this.recordingManagers[i].stopLocalRecording();
        }

        store.dispatch(eventStore.actions.updateRecordingStatus(false));

        this.recordingManagers = [];
    }

    setCameraSetting(newCameraSetting, shouldDispatch = true) {
        this.cameraSetting = newCameraSetting;

        this.updateHandleCameraAndShare();
        if (shouldDispatch) store.dispatch(eventStore.actions.setCameraSetting(newCameraSetting));
    }

    setCameraDevices(cameraDevices) {
        this.cameraDevices = cameraDevices;
        store.dispatch(eventStore.actions.setCameraDevices(this.cameraDevices));
    }

    setAudioOutputDevices(audioOutputDevices) {
        this.audioOutputDevices = audioOutputDevices;
        store.dispatch(eventStore.actions.setAudioOutputDevices(this.audioOutputDevices));
    }

    setAudioInputDevices(audioInputDevices) {
        this.audioInputDevices = audioInputDevices;
        store.dispatch(eventStore.actions.setAudioInputDevices(this.audioInputDevices));
    }

    setBackgroundSetting(background, shouldDispatch = true) {
        this.background = background;

        this.updateHandleCameraAndShare();
        if (shouldDispatch) store.dispatch(eventStore.actions.setBgSetting(background));
    }

    onConnectionSuccess(jingleSession) {
        console.log("🚀 ~ JitsiMeeting ~ onConnectionSuccess ~ jingleSession:", jingleSession);
        this.room = this.connection.initJitsiConference(
            this.event ? this.event.event_name : "conference",
            {
                ...confOptions,
                statisticsId: this.user ? this.user.email : this.event.userName
            }
        );
        console.log("🚀 ~ JitsiMeeting ~ onConnectionSuccess ~ this.room:", this.room);

        this.setReceiverConstraints();
        this.room.setSenderVideoConstraint(720); // After update, this should be set.

        let displayName = this.user ? this.user.name : this.event.userName;
        if ((this.user ? this.user.role : UserRole.USER) === UserRole.INTERPRETER) {
            displayName = `(T) ${this.user ? this.user.name : this.event.userName}`;
            this.room.setLocalParticipantProperty("d_output", this.selfRoomname);
            // Set default output roomname
            this.room.setLocalParticipantProperty("output", this.outputRoomname);
        }

        this.room.setDisplayName(displayName);
        this.room.setLocalParticipantProperty("role", this.user ? this.user.role : UserRole.USER);

        store.dispatch(eventStore.actions.setConference(this.room));

        this.onConferenceJoined = this.onConferenceJoined.bind(this);
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);
        this.onConferenceFailed = this.onConferenceFailed.bind(this);
        this.room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, this.onConferenceFailed);
        this.onUserJoined = this.onUserJoined.bind(this);
        this.room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
        this.onUserRoleChanged = this.onUserRoleChanged.bind(this);
        this.room.on(JitsiMeetJS.events.conference.USER_ROLE_CHANGED, this.onUserRoleChanged);
        this.onRemoteTrack = this.onRemoteTrack.bind(this);
        this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
        this.onTrackRemoved = this.onTrackRemoved.bind(this);
        this.room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onTrackRemoved);
        this.onUserLeft = this.onUserLeft.bind(this);
        this.room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
        this.onMessageReceived = this.onMessageReceived.bind(this);
        this.room.on(JitsiMeetJS.events.conference.MESSAGE_RECEIVED, (id, text, ts) =>
            this.onMessageReceived(id, text, ts)
        );
        this.room.on(JitsiMeetJS.events.conference.PRIVATE_MESSAGE_RECEIVED, (id, text, ts) =>
            this.onMessageReceived(id, text, ts, true)
        );
        this.room.addCommandListener("custom-lobby-message", data => {
            const { value, attributes } = data;
            const senderId = attributes.id;
            console.log(`📩 Message from admin: ${value}`);
            console.log(this.room.room.getLobby());
            return this.room.room.getLobby().sendMessage(value);
        });

        this.onSpeakerChanged = this.onSpeakerChanged.bind(this);
        this.room.on(JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED, this.onSpeakerChanged);
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            (track, actorParticipant) => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:341 ~ JitsiMeeting ~ TRACK_MUTE_CHANGED ~ track.isLocal():",
                    track.isLocal()
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:342 ~ JitsiMeeting ~ TRACK_MUTE_CHANGED ~ track:",
                    track
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:343 ~ JitsiMeeting ~ TRACK_MUTE_CHANGED ~ actorParticipant:",
                    actorParticipant
                );
                if (
                    actorParticipant &&
                    parseInt(actorParticipant.getProperty("role")) <= UserRole.EVENT_MANAGER
                ) {
                    if ((this.user ? this.user.role : UserRole.USER) == UserRole.INTERPRETER) {
                        this.changeMicOn(false);
                    } else {
                        this.changeMute(true);
                    }
                }
                // if (track.isLocal()) {
                //     this.changeMute(true, false);
                // }

                store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
            }
        );
        this.onAudioLevelChanged = this.onAudioLevelChanged.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED,
            (track, participantThatMutedUs) => {
                console.log(
                    "🚀 ~ JitsiMeeting ~ TRACK_MUTE_CHANGED ~ participantThatMutedUs:",
                    participantThatMutedUs
                );
                console.log("🚀 ~ JitsiMeeting ~ TRACK_MUTE_CHANGED ~ track:", track);
            }
        );
        this.room.on(JitsiMeetJS.events.conference.START_MUTED_POLICY_CHANGED, policy => {
            console.log(
                "🚀 ~ file: JitsiMeeting.js:397 ~ JitsiMeeting ~ START_MUTED_POLICY_CHANGED ~ policy:",
                policy
            );
            if (policy.audio) {
                if ((this.user ? this.user.role : UserRole.USER) == UserRole.INTERPRETER) {
                    this.changeMicOn(false);
                } else {
                    this.changeMute(true);
                }
            }
        });

        this.room.on(
            JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
            this.onAudioLevelChanged
        );
        this.handleFeatureChange = this.handleFeatureChange.bind(this);
        this.room.on(
            JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
            this.handleFeatureChange
        );
        this.handleModeratorEvent = this.handleModeratorEvent.bind(this);
        this.room.addCommandListener("moderator", this.handleModeratorEvent);
        this.room.on(
            JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,
            (userID, displayName) => {}
        );
        this.room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () => {});
        // this.room.on(
        //     JitsiMeetJS.events.conference.PARTICIPANT_KICKED,
        //     (actorParticipant, kickedParticipant, reason) => {
        //         console.log(actorParticipant);
        //         console.log(kickedParticipant);
        //         console.log(reason);
        //     }
        // );
        this.room.on(
            JitsiMeetJS.events.conference.KICKED,
            (actorParticipant, kickedParticipant, reason) => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:392 ~ JitsiMeeting ~ KICKED ~ (actorParticipant:",
                    actorParticipant
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:392 ~ JitsiMeeting ~ KICKED ~ kickedParticipant:",
                    kickedParticipant
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:392 ~ JitsiMeeting ~ KICKED ~ reason:",
                    reason
                );
                store.dispatch(
                    eventStore.actions.showNotification("error", "You are kicked by admin")
                );
                store.dispatch(eventStore.actions.endMeeting());
            }
        );
        this.room.on(JitsiMeetJS.events.conference.LOBBY_USER_JOINED, (id, name) => {
            console.log("🚀 ~ JitsiMeeting ~ LOBBY_USER_JOINED ~ id:", id);
            console.log("🚀 ~ JitsiMeeting ~ LOBBY_USER_JOINED ~ name:", name);
            console.log(
                "🚀 ~ JitsiMeeting ~ this.room.on ~ this.room.room.getLobby().members:",
                this.room.room.getLobby().lobbyRoom.members
            );
            console.log(
                "🚀 ~ JitsiMeeting ~ this.room.on ~ this.room.isMembersOnly():",
                this.room.isMembersOnly()
            );
            if (
                !(
                    this.event.event_type == EventType.MEETING ||
                    this.event.event_type == EventType.WEBINAR
                ) &&
                this.room.isMembersOnly()
            ) {
                this.room.disableLobby();
            }
            store.dispatch(
                eventStore.actions.setLobbyMembers(
                    this.room.room.getLobby().lobbyRoom.members ?? []
                )
            );
        });
        this.room.on(JitsiMeetJS.events.conference.LOBBY_USER_UPDATED, (id, options) => {
            console.log("🚀 ~ JitsiMeeting ~ LOBBY_USER_UPDATED ~ options:", options);
            console.log("🚀 ~ JitsiMeeting ~ LOBBY_USER_UPDATED ~ id:", id);
            console.log(
                "🚀 ~ JitsiMeeting ~ this.room.on ~ this.room.getLobby():",
                this.room.room.getLobby()
            );
            store.dispatch(
                eventStore.actions.setLobbyMembers(
                    this.room.room.getLobby().lobbyRoom.members ?? []
                )
            );
        });

        this.room.on(JitsiMeetJS.events.conference.LOBBY_USER_LEFT, isMembersOnly => {
            console.log("🚀 ~ JitsiMeeting ~ LOBBY_USER_LEFT ~ isMembersOnly:", isMembersOnly);
            if (this.room && this.room.room) {
                store.dispatch(
                    eventStore.actions.setLobbyMembers(
                        this.room.room.getLobby().lobbyRoom.members ?? []
                    )
                );
            }
        });

        if (
            this.event.event_type === EventType.WEBINAR &&
            (this.user ? this.user.role : UserRole.USER) >= UserRole.USER
        ) {
            this.room.setLocalParticipantProperty(
                "role",
                (this.user ? this.user.role : UserRole.USER) === UserRole.LITE_USER
                    ? UserRole.LITE_USER
                    : UserRole.OBSERVER
            );
        }

        this.room.join();

        // if (this.user.role <= UserRole.INTERPRETER) {
        //     this.room.join();
        // } else {
        //     this.room
        //         .joinLobby(this.user.name, this.user.email)
        //         .then(result => {
        //             console.log("🚀 ~ JitsiMeeting ~ joinLobby success");
        //         })
        //         .catch(error => {
        //             console.log(
        //                 "🚀 ~ file: JitsiMeeting.js:599 ~ JitsiMeeting ~ joinLobby ~ error:",
        //                 error
        //             );
        //         });
        // }
    }

    setReceiverConstraints(pId) {
        if (this.room) {
            const receiverConstraints = {
                defaultConstraints: { maxHeight: 720 },
                assumedBandwidthBps: 2000000,
                constraints: {}
            };

            if (!Array.isArray(pId)) {
                if (pId && !this.videoParticipants.find(elem => elem === pId)) {
                    this.videoParticipants.push(pId);
                }
            } else {
                this.videoParticipants = pId;
            }

            for (let i = 0; i < this.videoParticipants.length; i++) {
                receiverConstraints["constraints"][this.videoParticipants[i]] = {
                    maxHeight: 720
                };
            }
            this.room.setReceiverConstraints(receiverConstraints);
        }
    }

    onConnectionFailed(error) {
        console.log("🚀 ~ JitsiMeeting ~ onConnectionFailed ~ error:", error);
        console.log("Connection Failed");
    }

    disconnect() {
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
            this.onConnectionSuccess
        );
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_FAILED,
            this.onConnectionFailed
        );
        this.connection.removeEventListener(
            JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
            this.disconnect
        );
    }

    onDeviceListChanged(devices) {
        console.log("🚀 ~ JitsiMeeting ~ onDeviceListChanged ~ devices:", devices);
        let _audioOutputDevices = [],
            _audioInputDevices = [],
            _cameraDevices = [];
        let mediaOptions = []; // Initial mediaOptions
        let restOptions = {};

        devices.forEach(device => {
            switch (device.kind) {
                case "audiooutput":
                    _audioOutputDevices.push(device);
                    break;
                case "audioinput":
                    _audioInputDevices.push(device);
                    break;
                case "videoinput":
                    _cameraDevices.push(device);
                    break;
                default:
                    break;
            }
        });

        if (_audioOutputDevices.length > 0) {
            this.setAudioOutputDevices(_audioOutputDevices);

            if (this.audioOutputSetting === null || this.audioOutputSetting === "") {
                this.setAudioOutputSetting(_audioOutputDevices[0].deviceId);
            }
        }

        if (_audioInputDevices.length > 0) {
            mediaOptions.push("audio");
            this.setAudioInputDevices(_audioInputDevices);

            if (
                this.audioInputSetting === null ||
                this.audioInputSetting === "" ||
                _audioInputDevices.findIndex(this.audioInputSetting) === -1
            ) {
                restOptions.micDeviceId = _audioInputDevices[0].deviceId;
                this.setAudioInputSetting(_audioInputDevices[0].deviceId);
            }
        }

        if (_cameraDevices.length > 0) {
            if (this.isVideoOn) {
                if (_cameraDevices.length > 0) {
                    mediaOptions.push("video");
                }
            }
            this.setCameraDevices(_cameraDevices);

            if (
                this.cameraSetting === null ||
                this.cameraSetting === "" ||
                _cameraDevices.findIndex(this.cameraSetting) === -1
            ) {
                restOptions.cameraDeviceId = _cameraDevices[0].deviceId;
                this.setCameraSetting(_cameraDevices[0].deviceId);
            }
        }
    }

    async onLocalTracks(tracks) {
        console.log(
            "🚀 ~ file: JitsiMeeting.js:486 ~ JitsiMeeting ~ onLocalTracks ~ tracks:",
            tracks
        );
        for (let i = 0; i < tracks.length; i++) {
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED,
                audioLevel => {}
            );
            tracks[i].addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, () => {});
            tracks[i].addEventListener(JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED, () => {});
            tracks[i].addEventListener(
                JitsiMeetJS.events.track.TRACK_AUDIO_OUTPUT_CHANGED,
                deviceId => {}
            );

            if (tracks[i].getType() === "video") {
                //
            } else if (tracks[i].getType() === "audio") {
                if (this.isMuted) {
                    await tracks[i].mute();
                } else {
                    await tracks[i].unmute();
                }
            }

            if (this.isJoined === 2) {
                if (tracks[i].getType() === "audio") {
                    if (this.isMicOn) {
                        this.room.addTrack(tracks[i]);
                    }
                } else {
                    this.room.addTrack(tracks[i]);
                }
            }

            this.updateLocalTracks([tracks[i]]);
        }
    }

    async onConferenceJoined(data) {
        // if (this.user.role >= UserRole.USER) {
        //     if (this.room.isMembersOnly()) {
        //         this.room
        //             .joinLobby(this.user.name, this.user.email)
        //             .then(result => {
        //                 console.log("🚀 ~ JitsiMeeting ~ joinLobby success");
        //             })
        //             .catch(error => {
        //                 console.log(
        //                     "🚀 ~ file: JitsiMeeting.js:599 ~ JitsiMeeting ~ joinLobby ~ error:",
        //                     error
        //                 );
        //             });
        //     }
        // }

        console.log("🚀 ~ JitsiMeeting ~ onConferenceJoined ~ data:", data);
        store.dispatch(eventStore.actions.joinedConference());

        for (let i = 0; i < this.localTracks.length; i++) {
            if (this.localTracks[i].getType() === "audio" && this.isMuted) {
                await this.localTracks[i].mute();
            }
            await this.room.addTrack(this.localTracks[i]).catch(error => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:581 ~ JitsiMeeting ~ awaitthis.room.addTrack ~ error:",
                    error
                );
            });
        }

        if ((this.user ? this.user.role : UserRole.USER) >= UserRole.USER) {
            this.setRoomFeature();
        }
    }

    onConferenceFailed(error) {
        console.log(
            "🚀 ~ file: JitsiMeeting.js:587 ~ JitsiMeeting ~ onConferenceFailed ~ error:",
            error
        );

        if (
            this.room.isMembersOnly() ||
            error == "conference.connectionError.membersOnly" ||
            error == "conference.connectionError.notAllowed"
        ) {
            this.room
                .joinLobby(
                    this.user ? this.user.name : this.event.userName,
                    this.user ? this.user.email : this.event.userName
                )
                .then(result => {
                    console.log("🚀 ~ JitsiMeeting ~ joinLobby success");
                    store.dispatch(eventStore.actions.joinedLobby());
                })
                .catch(error => {
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:599 ~ JitsiMeeting ~ joinLobby ~ error:",
                        error
                    );
                });
        } else if (error === "conference.connectionError.accessDenied") {
            store.dispatch(
                eventStore.actions.showNotification(
                    "error",
                    "You are not allowed to join this meeting"
                )
            );
            setTimeout(() => {
                store.dispatch(eventStore.actions.endMeeting());
            }, 3000);
        } else if (error === "conference.authenticationRequired") {
            console.log(
                "🚀 ~ JitsiMeeting ~ onConferenceFailed ~ authenticationRequired:",
                this.room.isMembersOnly()
            );
            store.dispatch(eventStore.actions.waitForMeeting({ room: this.room }));
        } else {
            console.log(
                "🚀 ~ JitsiMeeting ~ onConferenceFailed ~ this.isMembersOnly():",
                this.room.isMembersOnly()
            );
        }
    }

    /**
     *
     * @param id
     */

    onUserJoined(id) {
        console.log("🚀 ~ file: JitsiMeeting.js:564 ~ JitsiMeeting ~ onUserJoined ~ id:", id);
        if (!this.remoteTracks[id]) {
            this.remoteTracks[id] = [];
            store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
        }
    }

    /**
     *
     * @param id
     */
    onUserLeft(id) {
        if (!this.remoteTracks[id]) {
            return;
        }
        if (!this.room.myUserId()) {
            return;
        }

        if (this.videoParticipants.find(elem => elem === id)) {
            delete this.videoParticipants[id];
            this.setReceiverConstraints([...this.videoParticipants]);
        }
        delete this.remoteTracks[id];

        store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));

        // remove grouptranslator if the left user is a group translator
        store.dispatch(eventStore.actions.setGroupTranslator(id));
    }

    async onUserRoleChanged(id, role) {
        console.log(
            "🚀 ~ file: JitsiMeeting.js:648 ~ JitsiMeeting ~ onUserRoleChanged ~ id, role:",
            id,
            role
        );
        console.log(
            "🚀 ~ JitsiMeeting ~ onUserRoleChanged ~ this.room.isMembersOnly():",
            this.room.isMembersOnly()
        );
        console.log(
            "🚀 ~ JitsiMeeting ~ onUserRoleChanged ~ this.room.isLobbySupported():",
            this.room.isLobbySupported()
        );
        if (role === "moderator") {
            if (
                this.event.event_type == EventType.MEETING ||
                this.event.event_type == EventType.WEBINAR
            ) {
                if (this.room.isLobbySupported() && !this.room.isMembersOnly()) {
                    try {
                        await this.room.enableLobby();
                    } catch (error) {
                        console.log("🚀 ~ JitsiMeeting ~ onUserRoleChanged ~ error:", error);
                    }
                }
            } else {
                try {
                    this.room.disableLobby();
                    console.log("🚀 ~ JitsiMeeting ~ onUserRoleChanged ~ disableLobby:");
                } catch (error) {
                    console.log("🚀 ~ JitsiMeeting ~ onUserRoleChanged ~ error:", error);
                }
            }
        }
    }

    /**
     * Handles remote tracks
     * @param track JitsiTrack object
     */
    onRemoteTrack(track) {
        console.log("🚀 ~ JitsiMeeting ~ onRemoteTrack ~ track:", track);
        if (track.isLocal()) {
            store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
            return;
        }

        const participantId = track.getParticipantId();
        const participant = this.room != null ? this.room.getParticipantById(participantId) : null;

        if (!this.remoteTracks[participantId]) {
            this.remoteTracks[participantId] = [track];
        } else {
            for (let i = 0; i < this.remoteTracks[participantId].length; i++) {
                const _track = this.remoteTracks[participantId][i];
                if (_track.getType() === track.getType()) {
                    const containers = _track.containers;
                    if (containers.length > 0) {
                        containers.forEach(container => {
                            const container_id = container.id;
                            if (container_id) {
                                if (_track.disposed) {
                                    _track.detach(document.getElementById(`${container_id}`));
                                }
                            }
                        });
                    }

                    _.remove(this.remoteTracks[participantId], this.remoteTracks[participantId][i]);
                    break;
                }
            }
            this.remoteTracks[participantId].push(track);
        }

        if ((this.user ? this.user.role : UserRole.USER) === UserRole.INTERPRETER) {
            const that = this;
            track.addEventListener(JitsiMeetJS.events.track.TRACK_MUTE_CHANGED, t => {
                if (participant && participant.getProperty("d_output") === that.selfRoomname) {
                    store.dispatch(eventStore.actions.setGroupTranslator(participant));
                }
            });

            if (participant && participant.getProperty("d_output") === this.selfRoomname) {
                try {
                    store.dispatch(eventStore.actions.setGroupTranslator(participant));
                } catch (err) {
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:710 ~ JitsiMeeting ~ onRemoteTrack ~ err:",
                        err
                    );
                }
            }
        }

        store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
        if (track.getType() === "audio") {
            this.changeLangTrack(this.inputRoomname);
        }
    }

    onTrackRemoved(track) {
        console.log("🚀 ~ JitsiMeeting ~ onTrackRemoved ~ track:", track);
        const participantId = track.getParticipantId();
        const containers = track.containers;
        if (containers.length > 0) {
            if (track.getType() === "video") {
                if (this.videoParticipants.find(elem => elem === participantId)) {
                    const newVideoParticipants = this.videoParticipants.filter(
                        elem => elem !== track.getParticipantId()
                    );
                    this.setReceiverConstraints(newVideoParticipants);
                } else {
                    this.room.setReceiverConstraints(this.videoParticipants);
                }
            }

            const container_id = containers[0].id;
            if (container_id) {
                track.detach(document.getElementById(`${container_id}`));
            }
        }

        if (track.disposed) {
            if (this.remoteTracks[participantId]) {
                for (let i = 0; i < this.remoteTracks[participantId].length; i++) {
                    const _track = this.remoteTracks[participantId][i];
                    if (_track.getType() === track.getType()) {
                        _.remove(
                            this.remoteTracks[participantId],
                            this.remoteTracks[participantId][i]
                        );
                        break;
                    }
                }
            }
        }

        this.changeLangTrack(this.inputRoomname);

        store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
    }

    async unload() {
        const currentTracks = this.localTracks;
        for (let i = 0; i < currentTracks.length; i++) {
            if (!currentTracks[i].disposed) {
                await currentTracks[i].dispose().catch(err => {
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:774 ~ JitsiMeeting ~ awaitcurrentTracks[i].dispose ~ err:",
                        err
                    );
                });
            }
        }

        if (this.room && this.room.room) {
            await this.room.leave().catch(error => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:779 ~ JitsiMeeting ~ awaitthis.room.leave ~ error:",
                    error
                );
            });
            await this.connection.disconnect().catch(err => {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:782 ~ JitsiMeeting ~ awaitthis.connection.disconnect ~ err:",
                    err
                );
            });
            if (this.videoEffect) {
                const videoEffectTracks = this.videoEffect._originalVideoStream.getVideoTracks();
                console.log("🚀 ~ JitsiMeeting ~ unload ~ videoEffect:", videoEffectTracks);
                if (videoEffectTracks.length > 0) {
                    videoEffectTracks[0].stop();
                }
            }
            store.dispatch(eventStore.actions.endMeetingSuccess());
        } else {
            store.dispatch(eventStore.actions.endMeetingSuccess());
        }
    }

    setRoomFeature = () => {
        if (this.room && this.room.room) {
            this.room.setLocalParticipantProperty("roomname", this.inputRoomname);
        }
    };

    handleRaiseHand = _isRaise => {
        if (this.room && this.room.room) {
            this.isRaise = _isRaise;
            this.room.setLocalParticipantProperty("hand", this.isRaise);
        }
    };

    changeLangTrack = newRoomName => {
        this.inputRoomname = newRoomName;

        store.dispatch(eventStore.actions.changeInputRoomname(this.inputRoomname));

        if (!this.room) {
            return;
        }
        const participants = this.room.getParticipants();
        let interpreter_ids = [];
        let mic_off_interpreters = [];

        for (let j = 0; j < participants.length; j++) {
            if (participants[j].getProperty("output") === this.inputRoomname) {
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:769 ~ JitsiMeeting ~ participants[j]:",
                    participants[j]
                );
                interpreter_ids.push(participants[j].getId());
            }

            if (participants[j].getProperty("output") === `non-${this.inputRoomname}`) {
                mic_off_interpreters.push(participants[j].getId());
            }
        }

        this._resetAudioInputs([]);
        this.recordingManagers.forEach(recordingManager => {
            recordingManager.disconnectAllAudioTracks();
        });

        this.audioMixer.streamsToMix = [];

        // if (this.audioMixer.channelMerger) {
        //     this.audioMixer.channelMerger.disconnect();
        // }

        // Inter
        // if (this.inputRoomname === this.outputRoomname) {
        //     const audioTrack = this.localTracks.find(
        //         t => t.getType() === "audio"
        //     );
        //     this.audioMixer.addMediaTrackStream(audioTrack);
        // }

        if (this.inputRoomname === ORIGINAL_ROOMNAME) {
            for (let key in this.remoteTracks) {
                console.log("🚀 ~ JitsiMeeting ~ key:", key);
                if (this.remoteTracks.hasOwnProperty(key)) {
                    const participant = this.room.getParticipantById(key);
                    console.log("🚀 ~ JitsiMeeting ~ participant:", participant);
                    if (participant) {
                        let _isInterpreter = isInterpreter(participants, key); // check if the participant is an interpreter
                        const audioTrack = this.remoteTracks[key].find(
                            t => t.getType() === "audio" && t.disposed === false
                        );

                        if (audioTrack) {
                            if (
                                (this.user ? this.user.role : UserRole.USER) <= UserRole.INTERPRETER
                            ) {
                                this.audioTracks[key] = {
                                    audioTrack: audioTrack,
                                    muted: _isInterpreter,
                                    volume: this.volume
                                };

                                if (
                                    (this.user ? this.user.role : UserRole.USER) <
                                    UserRole.INTERPRETER
                                ) {
                                    this.addAudioTrackToLocalRecording(audioTrack);
                                }
                            } else {
                                if (!_isInterpreter) {
                                    this.audioTracks[key] = {
                                        audioTrack: audioTrack,
                                        muted: false,
                                        volume: this.volume
                                    };
                                }
                            }
                        }
                    }
                }
            }
        } else {
            interpreter_ids.forEach(interpreter_id => {
                this._addParticipantAudio(interpreter_id);
            });

            if (
                this.isWithFloor ||
                (this.event.is_passthrough === 1 && interpreter_ids.length === 0)
            ) {
                for (let key in this.remoteTracks) {
                    if (
                        this.remoteTracks.hasOwnProperty(key) &&
                        interpreter_ids.indexOf(key) === -1
                    ) {
                        const participant = this.room.getParticipantById(key);
                        if (participant) {
                            const audioTrack = this.remoteTracks[key].find(
                                t => t.getType() === "audio" && t.disposed === false
                            );

                            if (audioTrack) {
                                let _isInterpreter = isInterpreter(participants, key); // check if the participant is an another relay interpreter
                                if (_isInterpreter) {
                                    if (
                                        (this.user ? this.user.role : UserRole.USER) <=
                                        UserRole.INTERPRETER
                                    ) {
                                        this.audioTracks[key] = {
                                            audioTrack: audioTrack,
                                            muted: true,
                                            volume: this.volume
                                        };
                                    }
                                } else {
                                    this.audioTracks[key] = {
                                        audioTrack: audioTrack,
                                        muted: false,
                                        volume: this.volume
                                    };
                                }

                                this.addAudioTrackToLocalRecording(audioTrack);
                            }
                        }
                    }
                }
            }

            // this.audioMixer.playFilter(this.room, this.isWithFloor);
        }

        store.dispatch(eventStore.actions.updateAudioTracks(this.audioTracks));
    };

    /**
     * Reset audio inputs except inputs
     * @param {Array} exceptParticipants - except participants ID to remain audio input
     */
    _resetAudioInputs = (exceptParticipants = []) => {
        let _remainedAudioTracks = {};

        if (exceptParticipants.length > 0) {
            for (var key in this.remoteTracks) {
                if (
                    this.remoteTracks.hasOwnProperty(key) &&
                    exceptParticipants.indexOf(key) !== -1
                ) {
                    _remainedAudioTracks = {
                        ..._remainedAudioTracks,
                        [key]: this.remoteTracks[key]
                    };
                }
            }
        }

        this.audioTracks = _remainedAudioTracks;
    };

    _addParticipantAudio = participantId => {
        if (this.remoteTracks.hasOwnProperty(participantId)) {
            for (let j = 0; j < this.remoteTracks[participantId].length; j++) {
                if (
                    this.remoteTracks[participantId][j].getType() === "audio" &&
                    this.remoteTracks[participantId][j].disposed === false
                ) {
                    this.audioTracks[participantId] = {
                        audioTrack: this.remoteTracks[participantId][j],
                        muted: false,
                        volume: this.volume
                    };
                    // this.audioMixer.addMediaTrackStream(
                    //     this.remoteTracks[participantId][j]
                    // );
                    break;
                } else {
                    const participant = this.room.getParticipantById(participantId);

                    if (participant) {
                        const audioTrack = participant.getTracksByMediaType("audio");
                        if (!audioTrack.disposed) {
                            this.audioTracks[participantId] = {
                                audioTrack: audioTrack,
                                muted: false,
                                volume: this.volume
                            };
                            // this.audioMixer.addMediaTrackStream(audioTrack);
                            break;
                        }
                    }
                }
            }
        }
    };

    onMessageReceived(id, text, ts, isPrivate = false) {
        if (this.room.myUserId() !== id) {
            store.dispatch(eventStore.actions.changeMessageStatus(true));
            this.receiveMessageObject = { id, text, ts, isPrivate };
            store.dispatch(eventStore.actions.updateMessage(this.receiveMessageObject));
        }
    }

    onSpeakerChanged(id) {
        const participant = this.room.getParticipantById(id);
        if (participant) {
            const role = parseInt(participant.getProperty("role"));
            if (this.event && this.event.event_type === EventType.CONFERENCE) {
                if (role && role === UserRole.EMITTER) {
                    this.setFocusedId(id);
                }
            } else {
                if (role && role !== UserRole.INTERPRETER) {
                    this.setFocusedId(id);
                }
            }
        }
    }

    setFocusedId(id) {
        this.focusedId = id;
        store.dispatch(eventStore.actions.setFocusedId(id));
    }

    onAudioLevelChanged(userId, audioLevel) {
        store.dispatch(eventStore.actions.setAudioLevelChanged({ userId, audioLevel }));
    }

    /**
     * Handle the participant property feature updates
     * @param {*} participant
     * @param {*} name Changed property
     * @param {*} oldValue
     * @param {*} newValue
     */
    handleFeatureChange(participant, name, oldValue, newValue) {
        console.log(
            "🚀 ~ file: JitsiMeeting.js:967 ~ JitsiMeeting ~ handleFeatureChange ~ name:",
            name
        );
        console.log(
            "🚀 ~ file: JitsiMeeting.js:967 ~ JitsiMeeting ~ handleFeatureChange ~ participant:",
            participant
        );
        console.log(
            "🚀 ~ file: JitsiMeeting.js:967 ~ JitsiMeeting ~ handleFeatureChange ~ newValue:",
            newValue
        );

        if (name === "roomname") {
            store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
        }

        if (name === "role") {
            if (parseInt(participant.getProperty("role")) <= UserRole.ADMIN) {
                // setIsStream(parseInt(participant.getProperty("stream")) === 1)
            }

            // Interpreter role seems not to be set in the beginning, so here check if interpreter has video track or not.
            if (parseInt(newValue) === UserRole.INTERPRETER) {
                store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
            }

            if (parseInt(participant.getProperty("role")) <= UserRole.USER) {
                // $(`#${participant.getId()}Place .hoverPlace`).hide();
            } else if (parseInt(participant.getProperty("role")) === UserRole.OBSERVER) {
                // $(`#${participant.getId()}Place .hoverPlace`).show();
            }

            this.changeLangTrack(this.inputRoomname);
        }

        if (name === "output") {
            store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
            if (participant.getProperty("d_output") === this.selfRoomname) {
                store.dispatch(eventStore.actions.setGroupTranslator(participant));
            }
            this.changeLangTrack(this.inputRoomname);
        }

        if (name === "d_output") {
            if (newValue === this.selfRoomname) {
                store.dispatch(eventStore.actions.setGroupTranslator(participant));
            }
            this.changeLangTrack(this.inputRoomname);
        }

        if (name === "hand") {
            store.dispatch(eventStore.actions.setParticipants(this.room.getParticipants()));
        }
    }

    handleModeratorEvent(results) {
        const value = results.value;
        if (value === this.room.myUserId()) {
            if (results.attributes.actionType === "notify") {
                if (results.attributes.content === "msg") {
                    store.dispatch(
                        eventStore.actions.showNotification("info", results.attributes.msg_en)
                    );
                }
            }

            if (results.attributes.actionType === "allow") {
                if (parseInt(results.attributes.content) === 1) {
                    this.room.setLocalParticipantProperty(
                        "role",
                        (this.user ? this.user.role : UserRole.USER) === UserRole.LITE_USER
                            ? UserRole.LITE_USER
                            : UserRole.USER
                    );
                    this.setDisableVideo(false);
                    this.setDisableMic(false);
                } else {
                    this.room.setLocalParticipantProperty(
                        "role",
                        this.user
                            ? this.user.role
                            : UserRole.USER === UserRole.LITE_USER
                            ? UserRole.LITE_USER
                            : UserRole.OBSERVER
                    );
                    this.setDisableVideo(true);
                    this.setDisableMic(true);
                }
            }

            if (results.attributes.actionType === "switch") {
                try {
                    switch (this.outputRoomname) {
                        case this.emitterRoomname:
                            this.setOutputRoomname(this.selfRoomname);
                            break;
                        case `non-${this.emitterRoomname}`:
                            this.setOutputRoomname(`non-${this.selfRoomname}`);
                            break;
                        case this.selfRoomname:
                            this.setOutputRoomname(this.emitterRoomname);
                            break;
                        case `non-${this.selfRoomname}`:
                            this.setOutputRoomname(`non-${this.emitterRoomname}`);
                            break;
                        default:
                            break;
                    }
                } catch (err) {
                    console.log("🚀 ~ JitsiMeeting ~ handleModeratorEvent ~ switch err:", err);
                }
            }
        }
    }

    /**
     *
     * @param {Array} newTracks
     */
    async updateLocalTracks(newTracks) {
        console.log("🚀 ~ JitsiMeeting ~ updateLocalTracks ~ newTracks:", newTracks);
        for (let i = 0; i < newTracks.length; i++) {
            const sameTypeTrack = this.localTracks.find(
                ot => ot.disposed || ot["videoType"] === newTracks[i]["videoType"]
            );
            console.log("🚀 ~ JitsiMeeting ~ updateLocalTracks ~ sameTypeTrack:", sameTypeTrack);
            if (sameTypeTrack) {
                if (!sameTypeTrack.disposed) {
                    if (
                        sameTypeTrack["videoType"] === "camera" &&
                        sameTypeTrack.deviceId === newTracks[i].deviceId
                    ) {
                        // If sharing video, do not dispose the video track
                    } else if (
                        sameTypeTrack["videoType"] === "desktop" &&
                        sameTypeTrack.deviceId === newTracks[i].deviceId
                    ) {
                        // If sharing video, do not dispose the video track
                    } else {
                        await sameTypeTrack.dispose().catch(err => {
                            console.log(
                                "🚀 ~ file: JitsiMeeting.js:1100 ~ JitsiMeeting ~ awaitsameTypeTrack.dispose ~ err:",
                                err
                            );
                        });
                    }
                }
                _.remove(this.localTracks, sameTypeTrack);
            }
            if (!newTracks[i].disposed) {
                this.localTracks.push(newTracks[i]);
            }
        }
        store.dispatch(eventStore.actions.updateLocalTracks(this.localTracks));
    }

    removeLocalTrackbyType(type) {
        const newTracks = this.localTracks.filter(track => track.getType() !== type);
        this.localTracks = newTracks;
        store.dispatch(eventStore.actions.updateLocalTracks(this.localTracks));
    }

    async updateHandleCameraAndShare() {
        const currentLocalTrack = this.localTracks.find(track => track.getType() === "video");
        const currentCameraLocalTrack = this.localTracks.find(
            track => track["videoType"] === "camera"
        );
        const currentDesktopLocalTrack = this.localTracks.find(
            track => track["videoType"] === "desktop"
        );
        // console.log(this.isCamera, this.isVideoOn);
        // console.log(currentDesktopLocalTrack ? currentDesktopLocalTrack["videoType"] : "");
        // console.log(currentCameraLocalTrack ? currentCameraLocalTrack["videoType"] : "");
        // console.log(currentLocalTrack ? currentLocalTrack["videoType"] : "");
        if (this.isCamera) {
            if (this.isVideoOn) {
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    try {
                        console.log(
                            "🚀 ~ file: JitsiMeeting.js:1128 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ currentLocalTrack:",
                            currentLocalTrack
                        );
                        await currentLocalTrack.setEffect(undefined);
                        await currentDesktopLocalTrack.dispose();

                        console.log(
                            "🚀 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ videoEffect:",
                            this.videoEffect
                        );
                    } catch (err) {
                        console.log(
                            "🚀 ~ file: JitsiMeeting.js:1133 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ err:",
                            err
                        );
                    }
                }
                if (this.isShareOtherCamera) {
                    const otherCameraDevice = this.cameraDevices.find(
                        device => device.deviceId !== this.cameraSetting
                    );
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:1141 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ :",
                        "1"
                    );
                    this._handleLocalDeviceTrack(
                        otherCameraDevice.deviceId,
                        this.cameraSetting,
                        this.background
                    );
                } else {
                    console.log(
                        "🚀 ~ file: JitsiMeeting.js:1147 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ :",
                        2
                    );
                    this._handleLocalDeviceTrack(this.cameraSetting, null, this.background);
                }
            } else {
                const otherCameraDevice1 = this.cameraDevices.find(
                    device => device.deviceId !== this.cameraSetting
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:1096 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ otherCameraDevice1:",
                    otherCameraDevice1
                );
                console.log(
                    "🚀 ~ file: JitsiMeeting.js:1097 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ currentLocalTrack:",
                    currentLocalTrack
                );
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    await currentLocalTrack.setEffect(undefined).catch(err => {
                        console.log(
                            "🚀 ~ file: JitsiMeeting.js:1163 ~ JitsiMeeting ~ awaitcurrentLocalTrack.setEffect ~ err:",
                            err
                        );
                    });

                    console.log(
                        "🚀 ~ JitsiMeeting ~ awaitcurrentLocalTrack.setEffect ~ videoEffect:",
                        this.videoEffect
                    );
                    if (!this.isShareOtherCamera) {
                        await currentLocalTrack.dispose().catch(err => {
                            console.log(
                                "🚀 ~ file: JitsiMeeting.js:1167 ~ JitsiMeeting ~ awaitcurrentLocalTrack.dispose ~ err:",
                                err
                            );
                        });
                        this.removeLocalTrackbyType("video");
                    } else if (currentLocalTrack.videoType === "desktop") {
                        await currentLocalTrack.dispose().catch(err => {
                            console.log(
                                "🚀 ~ file: JitsiMeeting.js:1172 ~ JitsiMeeting ~ awaitcurrentLocalTrack.dispose ~ err:",
                                err
                            );
                        });
                        const otherCameraDevice = this.cameraDevices.find(
                            device => device.deviceId !== this.cameraSetting
                        );
                        console.log(
                            "🚀 ~ file: JitsiMeeting.js:1178 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ 3:",
                            3
                        );
                        this._handleLocalDeviceTrack(otherCameraDevice.deviceId);
                    }
                } else {
                    if (this.isShareOtherCamera) {
                        const otherCameraDevice = this.cameraDevices.find(
                            device => device.deviceId !== this.cameraSetting
                        );
                        console.log(
                            "🚀 ~ file: JitsiMeeting.js:1188 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ 4:",
                            4
                        );
                        if (otherCameraDevice) {
                            this._handleLocalDeviceTrack(otherCameraDevice.deviceId);
                        }
                    }
                }
            }
        } else {
            if (this.isVideoOn) {
                if (
                    currentLocalTrack &&
                    !currentLocalTrack.disposed &&
                    currentLocalTrack.videoType === "desktop"
                ) {
                    // const effect = await mutePresenterVideo(
                    //     currentLocalTrack,
                    //     this.cameraSetting,
                    //     await this.getVirtualBackground(this.background)
                    // ).catch(err => {
                    //     console.log(
                    //         "🚀 ~ file: JitsiMeeting.js:1207 ~ JitsiMeeting ~ updateHandleCameraAndShare ~ err:",
                    //         err
                    //     );
                    // });

                    // this.videoEffect = effect;
                    // console.log(
                    //     "🚀 ~ JitsiMeeting ~ awaitcurrentLocalTrack.setEffect ~ this.videoEffect:",
                    //     this.videoEffect
                    // );

                    // await currentLocalTrack.setEffect(effect).catch(err => {
                    //     console.log(
                    //         "🚀 ~ file: JitsiMeeting.js:1210 ~ JitsiMeeting ~ awaitcurrentLocalTrack.setEffect ~ err:",
                    //         err
                    //     );
                    // });
                    // console.log(
                    //     "🚀 ~ JitsiMeeting ~ awaitcurrentLocalTrack.setEffect ~ currentLocalTrack:",
                    //     currentLocalTrack
                    // );
                    this._handleLocalDeviceTrack(this.cameraSetting, null, this.background);
                    const currentCreatedCameraLocalTrack = this.localTracks.find(
                        track => track["videoType"] === "camera"
                    );
                    console.log(currentCreatedCameraLocalTrack);
                    // this.updateLocalTracks(currentCreatedCameraLocalTrack, [currentLocalTrack]);
                    return;
                } else {
                    // console.log("---------here is something to fix-------------------");
                    // console.log(currentLocalTrack["videoType"]);
                    if (currentLocalTrack["videoType"] == "desktop") {
                        this._handleLocalDeviceTrack(this.cameraSetting, null, this.background);
                        // const currentCreatedCameraLocalTrack = this.localTracks.find(
                        //     track => track["videoType"] === "camera"
                        // );
                        // console.log(currentCreatedCameraLocalTrack);
                        // this.updateLocalTracks(currentCreatedCameraLocalTrack, [currentLocalTrack]);
                    } else {
                        this._handleShareDesktop();
                    }
                }
            } else {
                if (currentLocalTrack && !currentLocalTrack.disposed) {
                    if (currentCameraLocalTrack && !currentCameraLocalTrack.disposed) {
                        // console.log("Disposing only the camera track:", currentCameraLocalTrack);
                        await currentCameraLocalTrack.dispose();
                        this.localTracks = this.localTracks.filter(
                            track => track !== currentCameraLocalTrack
                        );
                    } else {
                        console.log("Camera track is already disposed or not available.");
                    }
                    // Ensure desktop track remains active
                    if (currentDesktopLocalTrack && !currentDesktopLocalTrack.disposed) {
                        this.updateLocalTracks([currentDesktopLocalTrack]);
                    }
                } else {
                    this._handleShareDesktop();
                }
            }
        }
    }

    /**
     * Add local video track with deviceId
     * @param {*} deviceId
     * @param {*} _cameraSetting if not null, local video track is set effect.
     * @param {*} _background if not null, local video track is set effect.
     */
    _handleLocalDeviceTrack(deviceId, _cameraSetting = null, _background = null) {
        JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            maxFps: 60,
            minFps: 30,
            cameraDeviceId: deviceId
        })
            .then(async ([newVideoTrack]) => {
                if (_cameraSetting) {
                    const effect = await mutePresenterVideo(
                        newVideoTrack,
                        _cameraSetting,
                        await this.getVirtualBackground(_background)
                    ).catch(err => console.error("Video Effect Error:", err));
                    this.videoEffect = effect;
                    await newVideoTrack
                        .setEffect(effect)
                        .catch(err => console.error("Set Effect Error:", err));
                } else if (_background) {
                    const virtualBackground = await this.getVirtualBackground(_background);
                    const effect = await createVirtualBackgroundEffect(virtualBackground);
                    await newVideoTrack
                        .setEffect(effect)
                        .catch(err => console.error("Set Background Error:", err));
                }
                if (this.room && this.room.room) {
                    await this.room
                        .addTrack(newVideoTrack)
                        .catch(err => console.error("Add Track Error:", err));
                }
                // ✅ Fix: Add camera track without removing existing screen share track
                this.updateLocalTracks([...this.localTracks, newVideoTrack]);
            })
            .catch(error => console.error("Camera Track Error:", error));
    }

    /**
     * Add desktop sharing track
     * @param {*} currentLocalTrack
     * @param {*} _cameraSetting if not null, desktop track is set effect
     */
    _handleShareDesktop(currentLocalTrack = null, _cameraSetting = null, background = null) {
        JitsiMeetJS.createLocalTracks({
            devices: ["desktop"],
            maxFps: 60,
            minFps: 30
        })
            .then(async newTracks => {
                const screenTrack = newTracks.find(t => t.videoType === "desktop");
                if (!screenTrack) return;
                // ✅ Fix: Dispose old screen track properly
                if (this.screenTrack) {
                    await this.screenTrack.dispose();
                }
                this.screenTrack = screenTrack;
                if (this.room && this.room.room) {
                    await this.room.addTrack(screenTrack);
                }
                // ✅ Fix: Keep both camera and screen tracks
                // console.log(screenTrack, ...this.localTracks);
                // this.updateLocalTracks([...this.localTracks, screenTrack]);
                this.updateLocalTracks([screenTrack]);
                // No need to manually attach to an HTML element (React handles it)
            })
            .catch(err => {
                console.error("Screen Share Error:", err);
                this.setIsCamera(true);
            });
    }

    /**
     * True is Camera, False is Desktop
     * @param {boolean} value
     */
    setIsCamera(value, shouldDispatch = true) {
        if (!value) {
            this.isShareOtherCamera = false;
        }
        this.isCamera = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch) store.dispatch(eventStore.actions.setIsCamera(this.isCamera));
    }

    /**
     *
     * @param {boolean} value
     */
    changeShareOtherCamera(value, shouldDispatch = true) {
        if (value) {
            this.isCamera = true;
        }
        this.isShareOtherCamera = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch)
            store.dispatch(eventStore.actions.setIsShareOtherCamera(this.isShareOtherCamera));
    }

    /**
     *
     * @param {boolean} value
     */
    changeVideoOn(value, shouldDispatch = true) {
        this.isVideoOn = value;

        this.updateHandleCameraAndShare();
        if (shouldDispatch) store.dispatch(eventStore.actions.changeVideoOn(this.isVideoOn));
    }

    setDisableVideo(disableVideo) {
        this.disableVideo = disableVideo;
        store.dispatch(eventStore.actions.setDisableVideo(this.disableVideo));
        this.changeVideoOn(false);
    }

    changeMicOn(isMicOn) {
        this.isMicOn = isMicOn;
        this.setAudioInputSetting();
        store.dispatch(eventStore.actions.changeMicOn(this.isMicOn));
        if (this.outputRoomname && this.outputRoomname !== "") {
            let outputRoomname = this.outputRoomname;
            outputRoomname = outputRoomname.replace("non-", "");
            if (!this.isMicOn && outputRoomname !== "") {
                outputRoomname = `non-${outputRoomname}`;
            }
            if ((this.user ? this.user.role : UserRole.USER) === UserRole.INTERPRETER)
                this.setOutputRoomname(outputRoomname);
        }
    }

    setDisableMic(disableMic) {
        this.disableMic = disableMic;
        store.dispatch(eventStore.actions.setDisableMic(this.disableMic));
        this.changeMicOn(!disableMic);
    }

    setIsWithFloor(value) {
        this.isWithFloor = value;
        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.setIsWithFloor(this.isWithFloor));
    }

    /**
     *
     * @param {*} outputRoomname
     */
    setOutputRoomname(outputRoomname) {
        this.outputRoomname = outputRoomname;
        if (this.room && this.room.room) {
            this.room.setLocalParticipantProperty("output", outputRoomname);
            this.changeLangTrack(this.inputRoomname);
        }

        store.dispatch(eventStore.actions.changeOutputRoomname(this.outputRoomname));
    }

    switchChannel(id) {
        if (!id) return;
        const attributes = {
            actionType: "switch"
        };

        this.room.sendCommandOnce("moderator", {
            value: id,
            attributes: attributes
        });
    }

    /**
     *
     * @param {*} selfRoomname
     */
    setSelfRoomname(selfRoomname) {
        this.selfRoomname = selfRoomname;
        if (this.room && this.room.room) {
            this.room.setLocalParticipantProperty("d_output", selfRoomname);
        }
    }

    /**
     *
     * @param {*} selfRoomname
     */
    setEmitterRoomname(emitterRoomname) {
        this.emitterRoomname = emitterRoomname;
    }

    changeMute(isMuted, reset = true) {
        this.isMuted = isMuted;

        if (reset) {
            this.setAudioInputSetting();
        }

        store.dispatch(eventStore.actions.changeMute(this.isMuted));
        console.log(
            "🚀 ~ file: JitsiMeeting.js:1430 ~ JitsiMeeting ~ changeMute ~ this.isMuted:",
            this.isMuted
        );
    }

    muteParticipants(value) {
        const participants = store.getState().event.participants;

        participants.forEach(participant => {
            if (!(parseInt(participant.getProperty("role")) <= UserRole.INTERPRETER)) {
                this.room.muteParticipant(participant.getId(), "audio");
            }
        });
    }

    setIsSubTitle(value) {
        this.isSubTitle = value;
        store.dispatch(eventStore.actions.setIsSubTitle(this.isSubTitle));
    }

    setHandOverReady(value, data) {
        if (this.room && this.room.room) {
            this.isHandOverReady = value;

            const attributes = {
                actionType: "notify",
                content: "msg",
                msg_en: data.msg_en
            };

            if (!this.isHandOverReady) {
                attributes["command"] = "handover";
            }
            this.room.sendCommandOnce("moderator", {
                value: data.participantId,
                attributes: attributes
            });
            store.dispatch(eventStore.actions.setHandOverReady(this.isHandOverReady));
        }
    }

    changeVolume(value) {
        if (value > 0) {
            if (this.volume <= 0.9) {
                this.volume = this.volume + value;
            } else {
                this.volume = 1;
            }
        } else {
            if (this.volume >= 0.1) {
                this.volume = this.volume + value;
            } else {
                this.volume = 0;
            }
        }

        if (this.audioMixer.gainNode) {
            this.audioMixer.gainNode.gain.value = this.volume;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeVolume(this.volume));
    }

    changeTreble(value) {
        if (value > 0) {
            if (this.treble <= 39) {
                this.treble = this.treble + value;
            } else {
                this.treble = 40;
            }
        } else {
            if (this.treble >= 1) {
                this.treble = this.treble + value;
            } else {
                this.treble = 0;
            }
        }

        if (this.audioMixer.trebleFilter) {
            this.audioMixer.trebleFilter.gain.value = this.treble;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeTreble(this.treble));
    }

    changeBass(value) {
        if (value > 0) {
            if (this.bass <= 39) {
                this.bass = this.bass + value;
            } else {
                this.bass = 40;
            }
        } else {
            if (this.bass >= 1) {
                this.bass = this.bass + value;
            } else {
                this.bass = 0;
            }
        }

        if (this.audioMixer.bassFilter) {
            this.audioMixer.bassFilter.gain.value = this.bass;
        }

        this.changeLangTrack(this.inputRoomname);
        store.dispatch(eventStore.actions.changeBass(this.bass));
    }

    async vbPreview(deviceId, virtualBackground) {
        const videoTracks = await JitsiMeetJS.createLocalTracks({
            devices: ["video"],
            maxFps: 60,
            minFps: 30,
            cameraDeviceId: deviceId
        });

        console.log("🚀 ~ JitsiMeeting ~ vbPreview ~ videoTracks:", videoTracks);
        if (videoTracks.length > 0) {
            const videoTrack = videoTracks[0];

            const effect = await createVirtualBackgroundEffect(virtualBackground);
            await videoTrack.setEffect(effect);
            return videoTrack;
        } else {
            return null;
        }
    }

    async getVirtualBackground(value) {
        let virtualBackground;
        if (value == null || value === "none") {
            virtualBackground = {
                backgroundEffectEnabled: false,
                selectedThumbnail: "none"
            };
        } else if (value == "half-blur") {
            virtualBackground = {
                backgroundEffectEnabled: true,
                backgroundType: VIRTUAL_BACKGROUND_TYPE.BLUR,
                blurValue: 8,
                selectedThumbnail: "slight-blur"
            };
        } else if (value == "blur") {
            virtualBackground = {
                backgroundEffectEnabled: true,
                backgroundType: VIRTUAL_BACKGROUND_TYPE.BLUR,
                blurValue: 25,
                selectedThumbnail: "blur"
            };
        } else {
            let imageUrl = "";
            if (value === this.event.bg_pic) {
                imageUrl = process.env.REACT_APP_FILE_URL + value;
            } else {
                const image = VIRTUAL_BACKGROUNDS.find(img => img.value === value);
                if (image) {
                    imageUrl = toAbsoluteUrl(image.image);
                }
            }
            if (imageUrl.length > 0) {
                try {
                    const url = await toDataURL(imageUrl);
                    virtualBackground = {
                        backgroundEffectEnabled: true,
                        backgroundType: VIRTUAL_BACKGROUND_TYPE.IMAGE,
                        selectedThumbnail: value,
                        virtualSource: url
                    };
                } catch (err) {
                    console.log("🚀 ~ getVirtualBackground ~ err:", err);
                    virtualBackground = {
                        backgroundEffectEnabled: false,
                        selectedThumbnail: "none"
                    };
                }
            }
        }

        return virtualBackground;
    }

    lobbyAccess(participantId, isApprove = false) {
        if (isApprove) {
            this.room.lobbyApproveAccess(participantId);
        } else {
            this.room.lobbyDenyAccess(participantId);
        }
    }

    addAudioTrackToLocalRecording(track) {
        console.log("🚀 ~ JitsiMeeting ~ addAudioTrackToLocalRecording ~ track:", track);
        this.recordingManagers.forEach((recordingManager, index) => {
            if (
                (this.user ? this.user.role : UserRole.USER) <= UserRole.EVENT_MANAGER &&
                recordingManager.isRecordingLocally()
            ) {
                const participantId = track.getParticipantId();
                const participant =
                    this.room != null ? this.room.getParticipantById(participantId) : null;

                console.log(
                    '🚀 ~ JitsiMeeting ~ addAudioTrackToLocalRecording ~ participant.getProperty("role"):',
                    participant.getProperty("role")
                );
                if (participant != null) {
                    if (
                        parseInt(participant.getProperty("role")) === UserRole.INTERPRETER &&
                        participant.getProperty("output") ===
                            recordingManager.inputRoomname.roomname &&
                        recordingManager.recordOption.languages
                    ) {
                        recordingManager.addAudioTrackToLocalRecording(
                            track.track,
                            CHANNEL_TYPE.LANGUAGES
                        );
                    } else if (
                        parseInt(participant.getProperty("role")) !== UserRole.INTERPRETER &&
                        recordingManager.recordOption.floor
                    ) {
                        recordingManager.addAudioTrackToLocalRecording(
                            track.track,
                            CHANNEL_TYPE.FLOOR
                        );
                    }
                }
            }
        });
    }

    sendPreMsg(participantId, msg) {
        if (this.room) {
            try {
                this.room.sendCommandOnce("moderator", {
                    value: participantId,
                    attributes: {
                        actionType: "notify",
                        content: "msg",
                        ...msg
                    }
                });
            } catch (e) {
                console.log("🚀 ~ JitsiMeeting ~ sendPreMsg ~ e:", e);
            }
        }
    }
}

export const jitsiMeeting = new JitsiMeeting();
